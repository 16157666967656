import { createGlobalStyle } from 'styled-components';

import BalsamiqRegularWoff from './BalsamiqSans-Regular.woff';
import BalsamiqRegularWoff2 from './BalsamiqSans-Regular.woff2';
import BalsamiqBoldWoff from './BalsamiqSans-Bold.woff';
import BalsamiqBoldWoff2 from './BalsamiqSans-Bold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Balsamiq Regular';
        src: local('Balsamiq Regular'), local('BalsamiqRegular'),
        url(${BalsamiqRegularWoff2}) format('woff2'),
        url(${BalsamiqRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Balsamiq Bold';
        src: local('Balsamiq Bold'), local('BalsamiqBold'),
        url(${BalsamiqBoldWoff2}) format('woff2'),
        url(${BalsamiqBoldWoff}) format('woff');
        font-weight: 700;
        font-style: bold;
    }
`;
