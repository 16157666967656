/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header/Header";
import * as S from "../../components/Global/styledWrapper"
import HomeInfo from "../../components/HomeInfo/HomeInfo";
import MemberContainer from "../../components/Member/MemberContainer";
import { useEffect, useRef, useState } from "react";
import _ from "lodash"
import MemberPreviewModal from "../../components/MemberPreviewModal/MemberPreviewModal";
import GlobalNoteContainer from "../../components/GlobalNote/GlobalNoteContainer";
import AddGlobalNoteModal from "../../components/GlobalNote/AddGlobalNoteModal";
import MembershipExtendModal from "../../components/Member/MembershipExtendModal";
import { useHistory } from "react-router-dom";
import axios from "../../util/axios-instance";
import { Oval } from "react-loader-spinner";
import colors from "../../styles/colors";

const Home = () => {
  const history = useHistory();
  const [state, setState] = useState({
    profilePreviewModal: { display: false, member: null },
    membershipExtendModal: { display: false, member: null },
    addNoteModal: false,
    searchValue: "",
    membersInfo: { data: [], loading: false, error: "" },
    notes: { data: [], loading: false, error: "" },
    members: { data: [], loading: false, error: "" },
    credits: { data: [], loading: false, error: "" },
    memberships: [],
    supplements: [],
    expiryMessageLoading: false,
    reminderMessageLoading: false,
  })

  const searchRef = useRef(null)
  const topRef = useRef(null)
  
  const { 
    profilePreviewModal, 
    membershipExtendModal, 
    searchValue, 
    addNoteModal, 
    membersInfo, 
    notes, 
    members,
    credits,
    memberships,
    supplements, 
    expiryMessageLoading,
    reminderMessageLoading,
  } = state

  const fetchMembersInfo = () => {
    setState(prevState => ({
      ...prevState,
      membersInfo: { data: prevState.membersInfo.data, loading: true, error: prevState.membersInfo.error }
    }))
    axios.get('/members/membersInfo')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        membersInfo: { data: res.data, loading: false, error: prevState.membersInfo.error }
      }))
    })
  }

  const fetchNotes = () => {
    setState(prevState => ({
      ...prevState,
      notes: { data: prevState.notes.data, loading: true, error: prevState.notes.error }
    }))
    axios.get('/notes')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        notes: { data: res.data, loading: false, error: prevState.notes.error }
      }))
    })
  }

  const deleteNote = (id) => {
    setState(prevState => ({
      ...prevState,
      notes: { data: prevState.notes.data, loading: true, error: prevState.notes.error }
    }))
    axios.delete(`/notes/${id}`)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        notes: { data: prevState.notes.data, loading: false, error: prevState.notes.error }
      }))
      fetchNotes();
    })
  }

  const addNote = (newNote) => {
    setState(prevState => ({
      ...prevState,
      notes: { data: prevState.notes.data, loading: true, error: prevState.notes.error }
    }))
    axios.post(`/notes`, newNote)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        notes: { data: prevState.notes.data, loading: false, error: prevState.notes.error }
      }))
      fetchNotes();
    })
  }

  const getExpiredMembers = (searchQuery) => {
    setState(prevState => ({
      ...prevState,
      members: { data: prevState.members.data, loading: true, error: prevState.members.error }
    }))
    const path = 
    searchQuery.trim().length > 0 ? 
      `/members/expired?searchQuery=${searchQuery}` : 
      `/members/expired`;
    axios.get(path)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        members: { data: res.data, loading: false, error: prevState.members.error }
      }))
      if (searchQuery.trim().length > 0) {
        searchRef.current.scrollIntoView()
      } else {
        topRef.current.scrollIntoView()
      }
    })
  }

  const updateMemberActive = (member) => {
    axios.patch(`/members/${member.id}/updateActive`)
    .then(res => {
      getExpiredMembers(searchValue);
    })
  }

  const updateMemberNote = (member, note) => {
    axios.patch(`/members/${member.id}/updateNote`, { note })
    .then(res => {
      getExpiredMembers("");
    })
  }

  const addMemberDebt = (member, debt) => {
    axios.post(`/payments/addMemberDebt/${member.id}`, debt)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const fetchMemberships = () => {
    axios.get('/prices/memberships')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        memberships: res.data,
      }))
    })
  }

  const fetchSupplements = () => {
    axios.get('/prices/supplements')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        supplements: res.data,
      }))
    })
  }

  const sellSupplement = (member, supplement) => {
    axios.post(`/payments/sellSupplement/${member.id}`, supplement)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const resolveDebt = (payment) => {
    axios.patch(`/payments/resolveDebt/${payment.id}`)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const extendMembership = (member, extendBody) => {
    axios.post(`/payments/extendMembership/${member.id}`, extendBody)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const editMember = (member, updateBody) => {
    axios.patch(`/members/${member.id}/updateProfile/`, updateBody)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const extendMembershipBeforeEnd = (member, extendBody) => {
    axios.post(`/payments/extendMembershipBeforeEnd/${member.id}`, extendBody)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const resetDate = (member) => {
    axios.patch(`/payments/resetMembershipDate/${member.id}`)
    .then(res => {
      getExpiredMembers("");
      fetchMembersInfo();
    })
  }

  const fetchCredits = (member) => {
    setState(prevState => ({
      ...prevState,
      credits: { data: prevState.credits.data, loading: true, error: prevState.credits.error }
    }))
    axios.get('/members/getCredits')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        credits: { data: res.data, loading: false, error: prevState.credits.error }
      }))
    })
  }

  useEffect(() => {
    document.title = "Alpha Gym - Почетна"
    if (!JSON.parse(sessionStorage.getItem("loggedInUser"))) {
      history.push('/login');
    } else {
      fetchMembersInfo()
      fetchNotes()
      fetchMemberships()
      fetchSupplements()
      fetchCredits()
    }
  }, []);

  const openPreviewModal = (member) => {
    setState((prevState) => ({
      ...prevState,
      profilePreviewModal: { display: true, member },
    }))
  }

  const closePreviewModal = () => {
    setState((prevState) => ({
      ...prevState,
      profilePreviewModal: { display: false, member: null },
    }))
  }

  const openMembershipExtendModal = (member) => {
    setState((prevState) => ({
      ...prevState,
      membershipExtendModal: { display: true, member },
    }))
  }

  const closeMembershipExtendModal = () => {
    setState((prevState) => ({
      ...prevState,
      membershipExtendModal: { display: false, member: null },
    }))
  }

  const openAddNoteModal = (member) => {
    setState((prevState) => ({
      ...prevState,
      addNoteModal: true,
    }))
  }

  const closeAddNoteModal = () => {
    setState((prevState) => ({
      ...prevState,
      addNoteModal: false,
    }))
  }

  const updateSearchValue = (searchValue) => {
    setState((prevState) => ({
      ...prevState,
      searchValue,
    }))
  }

  const searchMembers = (userInput) => {
    getExpiredMembers(userInput);
  }

  const debounceSearch = useRef(
    _.debounce((searchValue) => searchMembers(searchValue), 300, { trailing: true, leading: false }),
  )

  useEffect(() => {
    debounceSearch.current(searchValue)
  }, [searchValue])

  const sendExpiryMessage = () => {
    setState(prevState => ({
      ...prevState,
      expiryMessageLoading: true,
    }))
    axios.post('/payments/sendExpiryMessage')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        expiryMessageLoading: false,
      }))
      location.reload();
    })
  }

  const sendReminderMessage = () => {
    setState(prevState => ({
      ...prevState,
      reminderMessageLoading: true,
    }))
    axios.post('/payments/sendReminderMessage')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        reminderMessageLoading: false,
      }))
      location.reload();
    })
  }

  return (
    <>
      <Header 
        selected="Почетна" 
        memberships={memberships} 
        getExpiredMembers={getExpiredMembers}
        fetchMembersInfo={fetchMembersInfo} 
      />
      <MemberPreviewModal
        display={profilePreviewModal.display}
        member={profilePreviewModal.member}
        closeModal={closePreviewModal}
        updateMemberNote={updateMemberNote}
        addMemberDebt={addMemberDebt}
        memberships={memberships}
        supplements={supplements}
        sellSupplement={sellSupplement}
        resolveDebt={resolveDebt}
        editMember={editMember}
        extendMembershipBeforeEnd={extendMembershipBeforeEnd}
      />
      <MembershipExtendModal
        display={membershipExtendModal.display}
        member={membershipExtendModal.member}
        closeModal={closeMembershipExtendModal}
        extendMembership={extendMembership}
        memberships={memberships}
        resetDate={resetDate}
      />
      <AddGlobalNoteModal
        addNote={addNote}
        display={addNoteModal}
        closeModal={closeAddNoteModal}
      />
      <div ref={topRef}></div>
      <S.Wrapper>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
          <S.SubmitButton 
            cancel 
            onClick={() => sendExpiryMessage()}
          >
            {expiryMessageLoading ? 
              <Oval width={24} height={24} color={colors.white} strokeWidth={7} />
              : 'Поруке да је истекла чланарина'
            }
          </S.SubmitButton>
          <S.SubmitButton 
            cancel 
            onClick={() => sendReminderMessage()}
          >
            {reminderMessageLoading ? 
              <Oval width={24} height={24} color={colors.white} strokeWidth={7} />
              : 'Поруке да је рок још данас'
            }
          </S.SubmitButton>
        </div>
        <HomeInfo homePageInfo={membersInfo} credits={credits} />
        <GlobalNoteContainer
          openModal={openAddNoteModal}
          notes={notes}
          deleteNote={deleteNote}
        />
        <div ref={searchRef}></div>
        <MemberContainer 
          members={members}
          heading="Истекле чланарине"
          openPreviewModal={openPreviewModal}
          openMembershipExtendModal={openMembershipExtendModal}
          searchValue={searchValue}
          searchMembers={updateSearchValue}
          expiredMembers={true}
          updateMemberActive={updateMemberActive}
          pagination={false}
        />
      </S.Wrapper>
    </>
  );
}

export default Home;