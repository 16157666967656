import Select from 'react-select'
import colors from '../../styles/colors'

const SingleSelect = ({ options, handleSelection, disabled }) => {

  const optionColor = (state) => {
    if (state.isSelected) {
      return colors.red
    }
    if (state.isFocused) {
      return colors.header
    }
    return colors.darkGrey
  }

  return (
    <Select
      styles={{ 
        placeholder: (provided, state) => ({ ...provided, color: "white", fontFamily: "Balsamiq Regular" }),
        singleValue: (provided, state) => ({ ...provided, color: "white", fontFamily: "Balsamiq Regular" }),
        indicatorSeparator: (provided, state) => ({ ...provided, backgroundColor: colors.white }),
        dropdownIndicator: (provided, state) => ({ ...provided, color: `${colors.white} !important` }),
        input: (provided, state) => ({ ...provided, color: "white", fontFamily: "Balsamiq Regular" }),
        control: (provided, state) => ({
          ...provided, 
          border: `1px solid ${colors.white} !important`, 
          backgroundColor: colors.darkGrey, 
          boxShadow: "none" 
        }),
        menuList: (provided, state) => ({ ...provided, backgroundColor: colors.darkGrey }),
        option: (provided, state) => ({ 
          ...provided, 
          border: `1px solid ${colors.thinWhiteLine}`, 
          backgroundColor: optionColor(state), 
          color: colors.white, 
          fontFamily: "Balsamiq Regular",
          fontSize: 14,
        }),
      }}
      options={options} 
      onChange={(option) => handleSelection(option)}
      isDisabled={disabled}
    />
  );
}
 
export default SingleSelect;