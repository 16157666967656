import { getHighestDatePayment } from "../../util/app-util";
import * as S from "./styled/styledMember";
import moment from 'moment';

const MemberCard = ({ openPreviewModal, openMembershipExtendModal, member, expiredMembers, updateMemberActive }) => {

  const lockDisplay = () => {
    if (expiredMembers) {
      return (
        <S.MemberCardLock onClick={() => updateMemberActive(member)} />
      )
    }
    if (!member.active) {
      return (
        <S.MemberCardLockOpen onClick={() => updateMemberActive(member)} />
      )
    }
    return
  }

  return (
    <S.MemberCardContainer>
      <S.MemberCardId>{member.id}</S.MemberCardId>
      {expiredMembers && <S.MemberCardRenew onClick={() => openMembershipExtendModal(member)}>Продужи чланарину</S.MemberCardRenew>}
      {expiredMembers && <S.MemberCardDate>{moment(getHighestDatePayment(member).endDate).format('DD/MM/YYYY HH:mm')}</S.MemberCardDate>}
      {member.note && <S.MemberCardNote />}
      <S.MemberCardName>{`${member.firstName} ${member.lastName}`}</S.MemberCardName>
      <S.MemberCardPreview onClick={() => openPreviewModal(member)}>Преглед</S.MemberCardPreview>
      {lockDisplay()}
      <S.MemberCardImage memberImage={member.photoUrl} />
    </S.MemberCardContainer>
  );
}
 
export default MemberCard;