import axios from "axios";
const instance = axios.create({
  baseURL: "https://api.alphagymapp.rs",
  // baseURL: "https://localhost:44326/api",
  // baseURL: "http://localhost:4000/",
});
// 164.68.102.45
// instance.defaults.headers.common[
//   "Authorization"
// ] = `Bearer ${localStorage.getItem("jwtToken")}`;
export default instance;
//2021-11-09