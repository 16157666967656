import * as S from "./styled/styledGlobalNote"
import { CgNotes } from "react-icons/cg"
import colors from "../../styles/colors";
import moment from "moment";

const GlobalNote = ({ note, deleteNote }) => {
  return (
    <S.GlobalNoteCard>
      <S.GlobalNoteCardHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CgNotes size={22} color={colors.white} />
          <S.GlobalNoteCardInfo>{`${note.addedBy} - ${moment(note.date).format('DD/MM/YYYY')}`}</S.GlobalNoteCardInfo>
        </div>
        <S.GlobalNoteCardDelete onClick={() => deleteNote(note.id)} />
      </S.GlobalNoteCardHeader>
      <S.GlobalNoteContent>{note.text}</S.GlobalNoteContent>
    </S.GlobalNoteCard>
  );
}
 
export default GlobalNote;