import styled from 'styled-components';
import colors from '../../../styles/colors';

export const MemberPreviewModalOptions = styled.div`
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid ${colors.thinWhiteLine};
  margin-bottom: 15px;
`
export const MemberPreviewModalOption = styled.div`
  padding: 7px;
  background-color: ${props => props.selected ? colors.red : colors.header};
  border-radius: 7px;
  color: ${colors.white};
  font-family: 'Balsamiq Regular';
  cursor: pointer;
  margin-right: 7px;
  margin-bottom 8px;
`

// Modal Content - Membership
export const MembershipEnds = styled.div`
  font-size: 18px;
  color: ${colors.white};
  font-family: 'Balsamiq Regular';
  display: flex;
  align-items: center;
`
export const MembershipsTable = styled.table`
  width: 100%;
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
  text-align: left;
  border: 1px solid ${colors.thinWhiteLine};
  border-collapse: collapse;
  margin-top: 15px;
`
export const MembershipsTableRow = styled.tr`
  background-color: ${colors.darkGrey};
  &:nth-child(odd){
    background-color: ${props => !props.head && colors.header};
  }
`
export const MembershipsTableTh = styled.th`
  padding: 8px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`
export const MembershipsTableTd = styled.td`
  padding: 10px 8px 8px 8px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    hyphens: auto;
  }
`
export const OverallPaid = styled.div`
  font-size: 18px;
  color: ${colors.white};
  font-family: 'Balsamiq Regular';
  margin-top: 15px;
  display: flex;
  align-items: center;
`
