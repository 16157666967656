/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header/Header";
import * as Global from "../../components/Global/styledWrapper"
import MemberContainer from "../../components/Member/MemberContainer";
import { useEffect, useRef, useState } from "react";
import _ from "lodash"
import MemberPreviewModal from "../../components/MemberPreviewModal/MemberPreviewModal";
import { useHistory } from "react-router-dom";
import axios from "../../util/axios-instance";

const options = [
  { id: 1, label: "Активни Чланови"},
  { id: 2, label: "Неактивни Чланови"},
]

const Members = () => {
  const history = useHistory()

  const [state, setState] = useState({
    profilePreviewModal: { display: false, member: null },
    searchValue: "",
    optionSelected: 1,
    members: { data: [], loading: false, error: "" },
    memberships: [],
    supplements: [],
    inactiveMembers: { data: [], loading: false, error: "", total: 0, page: 1 },
  })

  const { profilePreviewModal, searchValue, optionSelected, members, memberships, supplements, inactiveMembers } = state

  const optionRef = useRef(1)

  const resetInactiveMembers = () => {
    setState(prevState => ({
      ...prevState,
      inactiveMembers: { 
        data: [], 
        loading: false, 
        error: "", 
        total: 0, 
        page: 1 
      }
    }))
  }

  const getActiveMembers = (searchQuery) => {
    setState(prevState => ({
      ...prevState,
      members: { data: prevState.members.data, loading: true, error: prevState.members.error }
    }))
    const path = 
    searchQuery.trim().length > 0 ? 
      `/members/active?searchQuery=${searchQuery}` : 
      `/members/active`;
    axios.get(path)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        members: { data: res.data, loading: false, error: prevState.members.error }
      }))
    })
  }

  const getInactiveMembers = (searchQuery, page) => {
    setState(prevState => ({
      ...prevState,
      inactiveMembers: { data: prevState.inactiveMembers.data, loading: true, error: prevState.inactiveMembers.error, total: prevState.inactiveMembers.total, page: prevState.inactiveMembers.page }
    }))
    const path = 
    searchQuery.trim().length > 0 ? 
      `/members/inactive?page=${page}&searchQuery=${searchQuery}` : 
      `/members/inactive?page=${page}`;
    axios.get(path)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        inactiveMembers: { 
          data: searchQuery.trim().length > 0 && page === 1 ? [].concat(res.data.members) : prevState.inactiveMembers.data.concat(res.data.members), 
          loading: false, 
          error: prevState.inactiveMembers.error, 
          total: res.data.total, 
          page: res.data.page 
        }
      }))
    })
  }

  const fetchMemberships = () => {
    axios.get('/prices/memberships')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        memberships: res.data,
      }))
    })
  }

  const fetchSupplements = () => {
    axios.get('/prices/supplements')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        supplements: res.data,
      }))
    })
  }

  const updateMemberActive = (member) => {
    axios.patch(`/members/${member.id}/updateActive`)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const updateMemberNote = (member, note) => {
    axios.patch(`/members/${member.id}/updateNote`, { note })
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const addMemberDebt = (member, debt) => {
    axios.post(`/payments/addMemberDebt/${member.id}`, debt)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const sellSupplement = (member, supplement) => {
    axios.post(`/payments/sellSupplement/${member.id}`, supplement)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const resolveDebt = (payment) => {
    axios.patch(`/payments/resolveDebt/${payment.id}`)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const editMember = (member, updateBody) => {
    axios.patch(`/members/${member.id}/updateProfile/`, updateBody)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  const extendMembershipBeforeEnd = (member, extendBody) => {
    axios.post(`/payments/extendMembershipBeforeEnd/${member.id}`, extendBody)
    .then(res => {
      if (optionSelected === 1) {
        getActiveMembers("")
      } else {
        resetInactiveMembers()
        getInactiveMembers("", 1)
      }
    })
  }

  useEffect(() => {
    document.title = "Alpha Gym - Чланови"
    if (!JSON.parse(sessionStorage.getItem("loggedInUser"))) {
      history.push('/login');
    } else {
      fetchMemberships()
      fetchSupplements()
    }
  }, []);

  const openPreviewModal = (member) => {
    setState((prevState) => ({
      ...prevState,
      profilePreviewModal: { display: true, member },
    }))
  }

  const closePreviewModal = () => {
    setState((prevState) => ({
      ...prevState,
      profilePreviewModal: { display: false, member: null },
    }))
  }

  const updateSearchValue = (searchValue) => {
    setState((prevState) => ({
      ...prevState,
      searchValue,
    }))
  }

  const searchMembers = (userInput) => {
    if (optionRef.current === 1) {
      getActiveMembers(userInput)
    } else {
      resetInactiveMembers()
      getInactiveMembers(userInput, 1)
    }
  }

  const debounceSearch = useRef(
    _.debounce((searchValue) => searchMembers(searchValue), 300, { trailing: true, leading: false }),
  )

  useEffect(() => {
    debounceSearch.current(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (searchValue === "") {
      if (optionSelected === 1) {
        getActiveMembers(searchValue)
      } else {
        resetInactiveMembers()
        getInactiveMembers(searchValue, 1)
      }
    }
  }, [optionSelected])

  return (
    <>
      <Header 
        selected="Чланови"
        memberships={memberships} 
        getMembers={() => {
          if (optionRef.current === 1) {
            getActiveMembers("")
          } else {
            resetInactiveMembers()
            getInactiveMembers("", 1)
          }
        }}
      />
      <MemberPreviewModal
        display={profilePreviewModal.display}
        member={profilePreviewModal.member}
        closeModal={closePreviewModal}
        updateMemberNote={updateMemberNote}
        addMemberDebt={addMemberDebt}
        memberships={memberships}
        supplements={supplements}
        sellSupplement={sellSupplement}
        resolveDebt={resolveDebt}
        editMember={editMember}
        extendMembershipBeforeEnd={extendMembershipBeforeEnd}
      />
      <Global.Wrapper>
        <Global.SelectionContainer>
          {options.map((option) => (
            <Global.Selection
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  optionSelected: option.id,
                  searchValue: "",
                }))
                optionRef.current = option.id
              }} 
              key={option.id} 
              selected={optionSelected === option.id}
            >
              {option.label}
            </Global.Selection>
          ))}
        </Global.SelectionContainer>
        <MemberContainer 
          members={optionSelected === 1 ? members : inactiveMembers}
          heading=""
          openPreviewModal={openPreviewModal}
          searchValue={searchValue}
          searchMembers={updateSearchValue}
          expiredMembers={false}
          pagination={optionSelected === 1 ? false : true}
          getInactiveMembers={getInactiveMembers}
          updateMemberActive={updateMemberActive}
        />
      </Global.Wrapper>
    </>
  );
}
 
export default Members;