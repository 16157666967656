const colors = {
  white: "white",
  black: "black",
  lightGrey: "#cccccc",
  darkGrey: "#171717",
  red: "#ff0046",
  background: "#000",
  transparent: "transparent",
  blue: "#1387f2",
  green: "#31d400",
  gold: "#ffd700",
  
  header: "#262626",
  modalBlack: "#00000080",
  thinWhiteLine: "#ffffff1a",
  thinRedLine: "#ff00464a",
}

export default colors