/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Global from "../../components/Global/styledWrapper";
import Header from "../../components/Header/Header";
import SingleSelect from "../../components/SingleSelect";
import StatsInfo from "../../components/StatsInfo";
import { getMonths, getYears } from "../../util/app-util";
import axios from "../../util/axios-instance";
import * as S from "./styled/styledStats";

const Stats = () => {
  const history = useHistory();

  const [state, setState] = useState({
    memberships: [],
    year: null,
    month: null,
    selectYear: null,
    selectMonth: null,
    stats: null,
  })
  
  const { memberships, year, month, selectYear, selectMonth, stats } = state
  const { overallSum, overallSupplementsSum, overallMembersCount } = stats || {}

  const fetchMemberships = () => {
    axios.get('/prices/memberships')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        memberships: res.data,
      }))
    })
  }

  useEffect(() => {
    document.title = "Alpha Gym - Статистика"
    const loggedInUsername = JSON.parse(sessionStorage.getItem("loggedInUser"))?.username;
    if (!JSON.parse(sessionStorage.getItem("loggedInUser"))) {
      history.push('/login');
    } else {
      if (loggedInUsername === 'brle') {
        fetchMemberships()
      } else {
        history.push('/');
      }
    }
  }, []);

  const displayStats = () => {
    if (selectYear) {
      setState(prevState => ({
        ...prevState,
        year: selectYear,
        month: selectMonth,
      }))
      axios.get(`payments/paymentsStats/${selectYear}/${selectMonth || 0}`)
      .then(res => {
        setState(prevState => ({
          ...prevState,
          stats: res.data,
        }))
      })
    }
  }

  return (
    <>
    <Header selected="Статистика" memberships={memberships} />
    <Global.Wrapper>
      <S.SelectionContainer>
        <S.SelectContainer>
          <S.Label>Година</S.Label>
          <SingleSelect
            options={getYears()} 
            handleSelection={(option) => { 
              setState((prevState) => ({
                ...prevState,
                selectYear: option.value,
              }))
            }} 
          />
        </S.SelectContainer>
        <S.SelectContainer>
          <S.Label>Месец</S.Label>
          <SingleSelect
            options={getMonths(selectYear)} 
            handleSelection={(option) => { 
              setState((prevState) => ({
                ...prevState,
                selectMonth: option.value,
              }))
            }} 
            disabled={!selectYear}
          />
        </S.SelectContainer>
      </S.SelectionContainer>
      <S.DisplayStatsButton onClick={() => displayStats()}>Прикажи Статистику</S.DisplayStatsButton>
      {stats && (
        <StatsInfo statsInfo={{ overallSum, overallSupplementsSum, overallMembersCount, year, month }} />
      )}
    </Global.Wrapper>
  </>
  );
}
 
export default Stats;