import Modal from "../Modal";
import * as Global from "../Global/styledWrapper";
import colors from "../../styles/colors";
import SingleSelect from "../SingleSelect";
import { useEffect, useState } from "react";
import moment from "moment";
import { getHighestDatePayment } from "../../util/app-util";
import * as S from "./styled/styledMember";

const MembershipExtendModal = ({ display, member, closeModal, extendMembership, memberships, resetDate }) => {
  
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [selectedOption, setSelectedOption] = useState(null)
  const [endDate, setEndDate] = useState(member ? getHighestDatePayment(member).endDate : new Date())

  const getMembershipOptions = () => {
    const membershipOptions = []
    memberships.map((mem) => (
      membershipOptions.push({ value: mem, label: `${mem.name} - ${mem.value} РСД` })
    ))
    return membershipOptions
  }

  const submitExtendMembership = () => {
    const extendBody = {
      addedBy: loggedInUser.username,
      value: selectedOption.value,
      duration: selectedOption.duration,
      description: `Članarina: ${selectedOption.name} - ${member.firstName} ${member.lastName}`,
      type: selectedOption.type,
    }
    extendMembership(member, extendBody)
    closeModal()
  }

  const submitResetDate = async () => {
    await resetDate(member)
    setEndDate(new Date())
  }

  useEffect(() => {
    setEndDate(member ? getHighestDatePayment(member).endDate : new Date())
  }, [member])

  return (
    <>
      {display && (
        <Modal heading={`Продужење Чланарине - ${member.firstName} ${member.lastName}`} closeModal={closeModal} >
          <Global.ModalContentContainer>
            <Global.ModalLabel>
              Датум Истека:&nbsp;
              <p style={{ color: colors.red }}>
                {moment(endDate).format('DD/MM/YYYY HH:mm')}
              </p>
            </Global.ModalLabel>
            <Global.ModalLabel>
              Број дана од истека чланарине:&nbsp;
              <p style={{ color: colors.red }}>
                {moment(new Date()).diff(moment(endDate), 'days')}
              </p>
            </Global.ModalLabel>
            <Global.ModalLabel>Изабери Чланарину</Global.ModalLabel>
            <SingleSelect 
              options={getMembershipOptions()} 
              handleSelection={(option) => setSelectedOption(option.value)}
            />
            <S.MemberResetDateButton onClick={() => submitResetDate()}>Ресетуј Датум</S.MemberResetDateButton>
            <Global.SubmitButton onClick={() => submitExtendMembership()}>Потврди</Global.SubmitButton>
          </Global.ModalContentContainer>
        </Modal>
      )}    
    </>
  );
}
 
export default MembershipExtendModal;