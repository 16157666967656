import { useState } from "react";
import AddMemberModal from "./AddMemberModal";
import useWindowWidth from "../../util/useWindowWidth";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import { useHistory } from "react-router-dom";

const navigationLinksAdmin = [
  { name: "Почетна", path: "/" },
  { name: "Чланови", path: "/clanovi" },
  { name: "Пазар", path: "/pazar" },
  { name: "Цене", path: "/cene" },
  { name: "Статистика", path: "/statistika" },
]

const navigationLinks = [
  { name: "Почетна", path: "/" },
  { name: "Чланови", path: "/clanovi" },
  { name: "Пазар", path: "/pazar" },
  { name: "Цене", path: "/cene" },
]

const Header = ({ selected, memberships, getExpiredMembers, fetchMembersInfo, getMembers, fetchPaymentsInfo }) => {
  const history = useHistory();

  const width = useWindowWidth();

  const [addMemberModal, setAddMemberModal] = useState(false)

  const logout = () => {
    sessionStorage.setItem('loggedInUser', null);
    history.push('/login');
  }

  const loggedInUsername = JSON.parse(sessionStorage.getItem("loggedInUser"))?.username;

  return (
    <>
      {width > 992 ? (
        <HeaderDesktop 
          logout={logout} 
          navigationLinks={loggedInUsername === 'brle' ? navigationLinksAdmin : navigationLinks} 
          selected={selected} 
          setAddMemberModal={setAddMemberModal} 
        />
      ) : (
        <HeaderMobile 
          logout={logout} 
          width={width} 
          navigationLinks={loggedInUsername === 'brle' ? navigationLinksAdmin : navigationLinks} 
          selected={selected} 
          setAddMemberModal={setAddMemberModal} 
        />
      )}
      <AddMemberModal 
        display={addMemberModal} 
        closeModal={() => setAddMemberModal(false)} 
        memberships={memberships}
        getExpiredMembers={getExpiredMembers}
        fetchMembersInfo={fetchMembersInfo}
        selected={selected}
        getMembers={() => getMembers()}
        fetchPaymentsInfo={fetchPaymentsInfo}
      />
    </>
  );
}
 
export default Header;