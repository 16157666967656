import styled from "styled-components"
import colors from "../../../styles/colors";
import { IoCloseSharp } from "react-icons/io5"

const cardWidth = "370px";

export const GlobalNoteHeading = styled.p`
  width: 100%;
  text-align: left;
  color: ${colors.white};
  font-size: 32px;
  font-family: 'Balsamiq Bold';
  padding: 15px;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 500px;
    left: 0;
    bottom: 0;
    height: 3px;
    background-color: ${colors.red};
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
    &:after{
      width: 100%;
    }
  }
`;

export const GlobalNoteAdd = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 7px;
  background-color: ${colors.red};
  margin-top: 20px;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    align-self: center;
  }
`;

export const GlobalNoteAddText = styled.div`
  color: ${colors.white};
  font-size: 18px;
  font-family: 'Balsamiq Bold';
  margin-left: 5px;
`;

export const GlobalNoteContainer = styled.div`
  display: grid;
  width: 100%;
  margin: 25px 0;
  grid-auto-rows: auto;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(auto-fill,minmax(${cardWidth},1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 10px 0 25px;
    grid-auto-rows: unset;
    grid-auto-columns: unset;
    grid-auto-columns: unset;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }
`;

export const GlobalNoteCard = styled.div`
  display: flex;
  width: ${cardWidth};
  min-height: 200px;
  border-radius: 8px;
  background-color: ${colors.darkGrey};
  border: 2px solid ${colors.red};
  position: relative;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    margin: 15px 0;
    width: 100%;
  }
`;

export const GlobalNoteCardHeader = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.thinRedLine};
`;

export const GlobalNoteCardInfo = styled.p`
  font-size: 16px;
  font-family: 'Balsamiq Bold';
  color: ${colors.lightGrey};
  margin-left: 10px;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const GlobalNoteCardDelete = styled(IoCloseSharp)`
  color: ${colors.white};
  width: 26px;
  height: 26px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`

export const GlobalNoteContent = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  font-size: 16px;
  font-family: 'Balsamiq Bold';
  color: ${colors.white};
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    padding: 15px;
    line-height: 1.3;
  }
`;