import * as Global from "../Global/styledWrapper"
import { RiImageEditFill } from "react-icons/ri"
import { useState } from "react";
import colors from "../../styles/colors";
import Datetime from 'react-datetime';
import "../../../node_modules/react-datetime/css/react-datetime.css";
import moment from "moment";
import SingleSelect from "../SingleSelect";
import dummyMember from "../../images/dummyMember.jpg";
import { compress, getHighestDatePayment } from "../../util/app-util";
import axios from "../../util/axios-instance";

const extendMembershipDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "calc(100% - 15px)",
  borderTop: `2px solid ${colors.red}`,
  marginTop: 15,
  paddingTop: 10,
  marginBottom: 10,
}

const EditMember = ({ member, editMember, closeModal, extendMembershipBeforeEnd, memberships }) => {

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [state, setState] = useState({
    newImage: member.photoUrl || null,
    firstName: member.firstName || "",
    lastName: member.lastName || "",
    membershipEnds: getHighestDatePayment(member).endDate || new Date(),
    phoneNumber: member.phoneNumber || "",
  })

  const { newImage, firstName, lastName, membershipEnds, phoneNumber } = state
  
  const [selectedOption, setSelectedOption] = useState(null)

  const [imageHovered, setImageHovered] = useState(false)

  const getMembershipOptions = () => {
    const membershipOptions = []
    memberships.map((mem) => (
      membershipOptions.push({ value: mem, label: `${mem.name} - ${mem.value} РСД` })
    ))
    return membershipOptions
  }

  const saveEditChanges = () => {
    const editMemberBody = {
      firstName,
      lastName,
      phoneNumber,
      endDate: new Date(membershipEnds),
      photoUrl: newImage,
    }
    editMember(member, editMemberBody)
    closeModal()
  }

  const extendMembership = () => {
    const extendBeforeEndBody = {
      addedBy: loggedInUser.username,
      value: selectedOption.value,
      duration: selectedOption.duration,
      description: `Чланарина: ${selectedOption.name} - ${member.firstName} ${member.lastName}`,
      type: selectedOption.type,
    }
    extendMembershipBeforeEnd(member, extendBeforeEndBody)
    closeModal()
  }

  return (
    <>
      <Global.MemberImageContainer onMouseEnter={() => setImageHovered(true)} onMouseLeave={() => setImageHovered(false)}>
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/png, image/jpeg"
          onChange={async (e) => {
            const compressedImage = await compress(e.target.files[0], 0.2)
            const formData = new FormData();
            formData.append('image', compressedImage)
            await axios.post('/members/uploadImage', formData).then(res => {
              setState((prevState) => ({
                ...prevState,
                newImage: res.data,
              }))
            })
          }}
        />
        {imageHovered &&
          <RiImageEditFill
            color={colors.white}
            size={35}
            style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, margin: "auto", zIndex: 1 }}
          />
        }
        <Global.MemberImage imageHovered={imageHovered} src={newImage ? `https://api.alphagymapp.rs/${newImage}` : dummyMember} />
      </Global.MemberImageContainer>
      <Global.ModalLabel>Име</Global.ModalLabel>
      <Global.ModalTextInput
        value={firstName}
        placeholder="Унеси име..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            firstName: e.target.value,
          }))
        }}
      />
      <Global.ModalLabel>Презиме</Global.ModalLabel>
      <Global.ModalTextInput
        value={lastName}
        placeholder="Унеси презиме..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            lastName: e.target.value,
          }))
        }}
      />
      <Global.ModalLabel>Чланарина Истиче:&nbsp;<p style={{ color: colors.red }}>{moment(getHighestDatePayment(member).endDate).format("DD/MM/YYYY HH:mm")}</p></Global.ModalLabel>
      <Datetime
        dateFormat={moment(membershipEnds).format("DD/MM/YYYY")}
        className="input"
        id="examPeriodEndDateInput"
        required
        value={moment(membershipEnds)}
        onChange={(e) => setState((prevState) => ({
          ...prevState,
          membershipEnds: e._d,
        }))}
      />
      <Global.ModalLabel>Број Телефона</Global.ModalLabel>
      <Global.ModalTextInput
        value={phoneNumber}
        placeholder="Унеси број телефона..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            phoneNumber: e.target.value,
          }))
        }}
      />
      <Global.SubmitButton onClick={() => saveEditChanges()}>Потврди</Global.SubmitButton>
      <div style={extendMembershipDivStyle}>
        <Global.ModalLabel>Продужи Чланарину Пре Истека</Global.ModalLabel>
        <SingleSelect 
          options={getMembershipOptions()} 
          handleSelection={(option) => setSelectedOption(option.value)}
        />
      </div>
      <Global.SubmitButton onClick={() => extendMembership()}>Продужи</Global.SubmitButton>
    </>
  );
}
 
export default EditMember;