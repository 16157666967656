import { useState } from "react";
import * as Global from "../Global/styledWrapper"

const AddMemberNote = ({ member, closeModal, updateMemberNote }) => {

  const [note, setNote] = useState(member.note !== "" ? member.note : "")

  const addMemberNote = () => {
    if (note !== member.note) {
      updateMemberNote(member, note);
      closeModal();
    }
  }

  return (
    <>
      <Global.ModalTextArea
        placeholder="Унеси белешку..."
        value={note}
        onChange={(e) => {
          setNote(e.target.value)
        }}
      ></Global.ModalTextArea>
      <Global.SubmitButton onClick={() => addMemberNote()}>Потврди</Global.SubmitButton>
    </>
  );
}
 
export default AddMemberNote;