import { useEffect, useState } from "react";
import colors from "../../styles/colors";
import * as Global from "../Global/styledWrapper";
import Modal from "../Modal";
import { RiImageEditFill } from "react-icons/ri";
import dummyMember from "../../images/dummyMember.jpg";
import Checkbox from "../Checkbox/Checkbox";
import SingleSelect from "../SingleSelect";
import axios from "../../util/axios-instance";
import { compress } from "../../util/app-util";

const AddMemberModal = ({ 
  display, 
  closeModal, 
  memberships, 
  getExpiredMembers, 
  fetchMembersInfo, 
  selected, 
  getMembers, 
  fetchPaymentsInfo 
}) => {
  
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [state, setState] = useState({
    newImage: null,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    paid: false,
    selectedOption: null,
  })

  const { newImage, firstName, lastName, phoneNumber, paid, selectedOption } = state

  const [imageHovered, setImageHovered] = useState(false)

  const getMembershipOptions = () => {
    const membershipOptions = []
    memberships.map((mem) => (
      membershipOptions.push({ value: mem, label: `${mem.name} - ${mem.value} РСД` })
    ))
    return membershipOptions
  }

  const addMember = () => {
    const addMemberBody = {
      firstName,
      lastName,
      phoneNumber,
      photoUrl: newImage,
      addedBy: loggedInUser.username,
      value: paid ? selectedOption.value : 0,
      duration: paid ? selectedOption.duration : 0,
      description: paid ? `Članarina: ${selectedOption.name} - ${firstName} ${lastName}` : '',
      paid,
      type: paid ? selectedOption.type : 1,
    }
    axios.post('/members', addMemberBody).then(res => {
      if (selected === "Почетна") {
        getExpiredMembers("")
        fetchMembersInfo()
      }
      if (selected === "Чланови") {
        getMembers()
      }
      if (selected === "Пазар") {
        fetchPaymentsInfo()
      }
      closeModal()
    })
  }

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      newImage: null,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      paid: false,
      selectedOption: null,
    }))
  }, [display])

  return (
    <>
      {display && (
        <Modal heading={"Додавање Члана"} closeModal={closeModal}>
          <Global.ModalContentContainer>
            <Global.MemberImageContainer onMouseEnter={() => setImageHovered(true)} onMouseLeave={() => setImageHovered(false)}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg"
                onChange={async (e) => {
                  const compressedImage = await compress(e.target.files[0], 0.2)
                  const formData = new FormData();
                  formData.append('image', compressedImage)
                  axios.post('/members/uploadImage', formData).then(res => {
                    setState((prevState) => ({
                      ...prevState,
                      newImage: res.data,
                    }))
                  })
                }}
              />
              {imageHovered &&
                <RiImageEditFill
                  color={colors.white}
                  size={35}
                  style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, margin: "auto", zIndex: 1 }}
                />
              }
              <Global.MemberImage imageHovered={imageHovered} src={newImage ? `https://api.alphagymapp.rs/${newImage}` : dummyMember} />
            </Global.MemberImageContainer>
            <Global.ModalLabel>Име</Global.ModalLabel>
            <Global.ModalTextInput
              value={firstName}
              placeholder="Унеси име..."
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }))
              }}
            />
            <Global.ModalLabel>Презиме</Global.ModalLabel>
            <Global.ModalTextInput
              value={lastName}
              placeholder="Унеси презиме..."
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }))
              }}
            />
            <Global.ModalLabel>Број Телефона</Global.ModalLabel>
            <Global.ModalTextInput
              value={phoneNumber}
              placeholder="Унеси број телефона..."
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  phoneNumber: e.target.value,
                }))
              }}
            />
            <Checkbox
              style={{ marginTop: 5, padding: "5px 10px 15px 0" }}
              checked={paid}
              size={20}
              checkboxText="Плаћено"
              updateCheckbox={() => {
                setState((prevState) => ({
                  ...prevState,
                  paid: !paid,
                }))
              }} 
            />
            {paid && (
              <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 15px)" }}>
                <Global.ModalLabel>Изабери Чланарину</Global.ModalLabel>
                <SingleSelect
                  options={getMembershipOptions()} 
                  handleSelection={(option) => { 
                    setState((prevState) => ({
                      ...prevState,
                      selectedOption: option.value,
                    }))
                  }} 
                />
              </div>
            )}
            <Global.SubmitButton style={{ marginTop: 15 }} onClick={() => addMember()}>Потврди</Global.SubmitButton>
          </Global.ModalContentContainer>
        </Modal>
      )}
    </>
  );
}
 
export default AddMemberModal;