import AddMemberDebt from "./AddMemberDebt";
import AddMemberNote from "./AddMemberNote";
import EditMember from "./EditMember";
import Membership from "./Membership";
import SellSupplement from "./SellSupplement";
import * as Global from "../Global/styledWrapper";

const MemberPreviewModalContent = ({ 
  selected, 
  member, 
  closeModal, 
  updateMemberNote, 
  addMemberDebt,
  sellSupplement,
  memberships,
  supplements,
  resolveDebt,
  editMember,
  extendMembershipBeforeEnd,
}) => {
  return (
    <Global.ModalContentContainer>
      {selected === 1 && (
        <Membership member={member} resolveDebt={resolveDebt} closeModal={closeModal} />
      )}
      {selected === 2 && (
        <SellSupplement member={member} sellSupplement={sellSupplement} closeModal={closeModal} supplements={supplements} />
      )}
      {selected === 3 && (
        <EditMember member={member} editMember={editMember} closeModal={closeModal} extendMembershipBeforeEnd={extendMembershipBeforeEnd} memberships={memberships} />
      )}
      {selected === 4 && (
        <AddMemberNote closeModal={closeModal} updateMemberNote={updateMemberNote} member={member} />
      )}
      {selected === 5 && (
        <AddMemberDebt member={member} addMemberDebt={addMemberDebt} closeModal={closeModal} />
      )}
    </Global.ModalContentContainer>
  );
}
 
export default MemberPreviewModalContent;