import { useState } from "react";
import { getTypeValue } from "../../util/app-util";
import Checkbox from "../Checkbox/Checkbox";
import * as Global from "../Global/styledWrapper"

const AddMemberDebt = ({ member, addMemberDebt, closeModal }) => {

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [state, setState] = useState({
    description: "",
    amount: 0,
    membership: false,
    supplement: false,
    termin: false,
  })

  const { description, amount, membership, supplement, termin } = state

  const submitAddMemberDebt = () => {
    const debt = {
      addedBy: loggedInUser.username,
      value: amount,
      description,
      type: getTypeValue(membership, supplement, termin)
    }
    addMemberDebt(member, debt)
    setState(prevState => ({
      ...prevState,
      description: "",
      amount: 0,
      membership: false,
      supplement: false,
      termin: false,
    }))
    closeModal()
  }

  return (
    <>
      <Global.ModalLabel>Опис дуга</Global.ModalLabel>
      <Global.ModalTextInput
        value={description}
        placeholder="Унеси опис дуга..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            description: e.target.value,
          }))
        }}
      />
      <Global.ModalLabel>Вредност</Global.ModalLabel>
      <Global.ModalTextInput
        value={amount}
        placeholder="Унеси вредност..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            amount: !isNaN(e.target.value) ? e.target.value : prevState.amount,
          }))
        }}
      />
      <Checkbox 
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={membership}
        size={20}
        checkboxText="Чланарина"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !membership,
            supplement: !membership ? false : supplement,
            termin: !membership ? false : termin,
          }))
        }}
      />
      <Checkbox
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={supplement}
        size={20}
        checkboxText="Суплемент"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !supplement ? false : membership,
            supplement: !supplement,
            termin: !membership ? false : termin,
          }))
        }}
      />
      <Checkbox 
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={termin}
        size={20}
        checkboxText="Термин"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !termin ? false : membership,
            supplement: !termin ? false : supplement,
            termin: !termin,
          }))
        }}
      />
      <Global.SubmitButton onClick={() => submitAddMemberDebt()}>Потврди</Global.SubmitButton>
    </>
  );
}
 
export default AddMemberDebt;