import * as S from "./styled/styledHeader";
import { IoAddCircleSharp } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import colors from "../../styles/colors";
import alphaLogo from "../../images/alphaLogo.png";
import { useEffect, useState } from "react";

const HeaderMobile = ({ width, navigationLinks, selected, setAddMemberModal, logout }) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (width <= 992) {
      setOpenMenu(false);
    }
  }, [width])

  return (
    <>
      <S.Header>
        <S.HeaderContainer>
          <S.HeaderMobileLogo src={alphaLogo} alt="AlphaGym" />
          <S.HeaderMobileMenuButton onClick={() => setOpenMenu(true)}>
            <S.HeaderMobileMenuIcon />
          </S.HeaderMobileMenuButton>
        </S.HeaderContainer>
      </S.Header>
      <S.HeaderMobileMenuOverlay open={openMenu} onClick={() => setOpenMenu(false)}>
        <S.HeaderMobileMenu open={openMenu} onClick={(e) => e.stopPropagation()}>
          <S.HeaderMobileMenuHeader>
            <S.HeaderMobileMenuClose onClick={() => setOpenMenu(false)} />
          </S.HeaderMobileMenuHeader>
          <S.HeaderMobileMenuBody>
            <S.HeaderNav>
              <S.HeaderUl>
                {navigationLinks.map((link, index) => (
                  <S.HeaderLi key={index}>
                    <S.HeaderLink to={link.path} selected={selected === link.name}>
                      {link.name}
                    </S.HeaderLink>
                  </S.HeaderLi>
                ))}
              </S.HeaderUl>
            </S.HeaderNav>
            <S.HeaderButtonContainer>
              <S.HeaderButton onClick={() => setAddMemberModal(true)}>
                <IoAddCircleSharp size={28} color={colors.white} />
                <S.HeaderButtonText>Додај Члана</S.HeaderButtonText>
              </S.HeaderButton>
              <S.HeaderButton onClick={() => logout()} right secondary>
                <BiLogOut size={28} color={colors.white} />
                <S.HeaderButtonText>Одјава</S.HeaderButtonText>
              </S.HeaderButton>
            </S.HeaderButtonContainer>
          </S.HeaderMobileMenuBody>
        </S.HeaderMobileMenu>
      </S.HeaderMobileMenuOverlay>
    </>
  );
}
 
export default HeaderMobile;