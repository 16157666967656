import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import colors from "../../styles/colors";
import * as S from "./styled/styledPaymentsInfo";

const PaymentsInfo = ({ paymentsInfo }) => {
  const [state, setState] = useState({
    today: paymentsInfo.data?.today || 0, 
    month: paymentsInfo.data?.month || 0, 
    year: paymentsInfo.data?.year || 0, 
  })
  const { today, month, year } = state;

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      today: paymentsInfo.data?.today || 0,  
      month: paymentsInfo.data?.month || 0, 
      year: paymentsInfo.data?.year || 0,
    }))
  }, [paymentsInfo])

  const data = [
    { label: "Укупан пазар данас", value: today, money: true },
    { label: "Укупан пазар овај месец", value: month, money: true },
    { label: "Укупан пазар ове године", value: year, money: true },
  ]

  return (
    <S.HomeInfoContainer>
      {data.map((info, index) => (
        <S.HomeInfoBox key={index}>
          <S.HomeInfoBoxLabel>{info.label}</S.HomeInfoBoxLabel>
          {paymentsInfo.loading ? (
            <Oval width={24} height={24} color={colors.green} strokeWidth={7} />
          ) : (
            <S.HomeInfoBoxValue money={info.money}>
              {`${parseInt(info.value).toLocaleString()} РСД`}
            </S.HomeInfoBoxValue>
          )}

        </S.HomeInfoBox>
      ))}
    </S.HomeInfoContainer>
  );
}
 
export default PaymentsInfo;