import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./pages/Home";
import Login from "./pages/Login";
import GlobalFonts from './fonts/fonts';
import Members from './pages/Members';
import Prices from './pages/Prices';
import Payments from './pages/Payments';
import Stats from './pages/Stats';

function App() {
  return (
    <Router>
      <div className="App">
        <GlobalFonts />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/clanovi">
            <Members />
          </Route>
          <Route exact path="/cene">
            <Prices />
          </Route>
          <Route exact path="/pazar">
            <Payments />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/statistika">
            <Stats />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
