import { SUPPLEMENT_TYPE } from "../../util/app-util";
import * as S from "./styled/styledPrices"

const SupplementPrices = ({ supplements, openModal, deletePrice }) => {
  return (
    <S.PricesTable>
      <thead>
        <S.PricesTableRow head>
          <S.PricesTableTh>Назив</S.PricesTableTh>
          <S.PricesTableTh>Цена</S.PricesTableTh>
          <S.PricesTableTh></S.PricesTableTh>
        </S.PricesTableRow>
      </thead>
      <tbody>
        {supplements.map((supplement) => (
          <S.PricesTableRow key={supplement.id} head={false}>
            <S.PricesTableTd>{supplement.name}</S.PricesTableTd>
            <S.PricesTableTd price>{supplement.value}</S.PricesTableTd>
            <S.PricesTableTd>
              <S.PricesTableButtons>
                <S.PricesEditButton onClick={() => openModal(SUPPLEMENT_TYPE, supplement)} />
                <S.PricesDeleteButton onClick={() => deletePrice(supplement)} />
              </S.PricesTableButtons>
            </S.PricesTableTd>
          </S.PricesTableRow>
        ))}
      </tbody>
    </S.PricesTable>
  );
}
 
export default SupplementPrices;