import * as S from "./styled/styledCheckbox"
import { FaCheck } from "react-icons/fa"

const Checkbox = ({ checked, style, size, updateCheckbox, checkboxText }) => {
  return (
    <S.CheckboxContainer onClick={updateCheckbox} style={style}>
      <S.Checkbox checked={checked} size={size}>
        {checked &&  <FaCheck color="white" size={size - 6} />}
      </S.Checkbox>
      <S.CheckboxText>{checkboxText}</S.CheckboxText>
    </S.CheckboxContainer>
  );
}
 
export default Checkbox;