import * as Global from "../Global/styledWrapper";

const Modal = ({ heading, children, closeModal }) => {
  return (
    <Global.ModalOverlay onClick={() => closeModal()}>
      <Global.ModalBox onClick={(e) => e.stopPropagation()}>
        <Global.ModalHeader>
          <Global.ModalHeading>{heading}</Global.ModalHeading>
          <Global.ModalClose onClick={() => closeModal()} />
        </Global.ModalHeader>
        <Global.ModalBody>
          {children}
        </Global.ModalBody>
      </Global.ModalBox>
    </Global.ModalOverlay>
  );
}
 
export default Modal;