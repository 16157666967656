import { useState } from "react";
import * as Global from "../Global/styledWrapper"
import Modal from "../Modal";

const AddGlobalNoteModal = ({ display, closeModal, addNote }) => {

  const [note, setNote] = useState("")

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const addNoteSubmit = () => {
    addNote({ text: note, addedBy: loggedInUser.username })
    setNote("")
    closeModal()
  }

  return (
    <>
    {display && (
      <Modal heading={"Нова Белешка"} closeModal={closeModal}>
        <Global.ModalTextArea
          placeholder="Унеси белешку..."
          value={note}
          onChange={(e) => {
            setNote(e.target.value)
          }}
        ></Global.ModalTextArea>
        <Global.SubmitButton onClick={() => addNoteSubmit()}>Потврди</Global.SubmitButton>
      </Modal>
    )}
  </>
  );
}
 
export default AddGlobalNoteModal;