import * as S from "./styled/styledLogin";
import alphaLogoFull from "../../images/alphaLogoFull.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import colors from "../../styles/colors";
import axios from "../../util/axios-instance";

const Login = () => {
  const history = useHistory()
  const [state, setState] = useState({
    username: "",
    password: "",
    loading: false,
    errorMessage: "",
  })

  const { username, password, loading, errorMessage } = state

  useEffect(() => {
    document.title = "Alpha Gym - Пријава"
  }, []);

  const login = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    axios.post("/users/login", {
      username,
      password,
    }).then(res => {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }))
      sessionStorage.setItem("loggedInUser", JSON.stringify(res.data))
      history.push('/');
    }).catch(err => {
      if (err.response?.status === 404) {
        setState(prevState => ({
          ...prevState,
          errorMessage: "Погрешно корисничко име или лозинка",
          loading: false,
        }))
        setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            errorMessage: ""
          }))
        }, 3000);
      }
    })
  }

  return (
    <S.LoginContainer>
      <S.LoginBox>
        <S.LoginLogo src={alphaLogoFull} alt="Лого" />
        <S.LoginLabel>Корисничко Име</S.LoginLabel>
        <S.LoginInput 
          value={username}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              username: e.target.value,
            }))
          }}
          autoFocus
        />
        <S.LoginLabel>Лозинка</S.LoginLabel>
        <S.LoginInput 
          type="password"
          value={password}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              password: e.target.value,
            }))
          }}
        />
        {errorMessage && <S.LoginError>{errorMessage}</S.LoginError>}
        <S.LoginButton onClick={() => login()}>
          {loading ? (
            <Oval width={24} height={24} color={colors.white} strokeWidth={7} />
          ) : (
            `Пријава`
          )}
        </S.LoginButton>
      </S.LoginBox>
    </S.LoginContainer>
  );
}
 
export default Login;