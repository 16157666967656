import * as S from "./styled/styledMemberPreviewModal";
import { useEffect, useState } from "react";
import MemberPreviewModalContent from "./MemberPreviewModalContent";
import Modal from "../Modal";

const options = [
  { id: 1, label: "Чланарина"},
  { id: 2, label: "Продај Суплементе"},
  { id: 3, label: "Измени Профил"},
  { id: 4, label: "Додај Белешку"},
  { id: 5, label: "Додај Дуг"},
]

const MemberPreviewModal = ({ 
  display, 
  member, 
  closeModal, 
  updateMemberNote, 
  addMemberDebt, 
  sellSupplement,
  memberships,
  supplements,
  resolveDebt,
  editMember,
  extendMembershipBeforeEnd,
}) => {
  const [state, setState] = useState({
    optionSelected: 1,
  })

  const { optionSelected } = state

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      optionSelected: 1,
    }))
  }, [display])
  

  return (
    <>
      {display && (
        <Modal heading={`${member.firstName} ${member.lastName}`} closeModal={closeModal}>
          <S.MemberPreviewModalOptions>
            {options.map((option) => (
              <S.MemberPreviewModalOption
                onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  optionSelected: option.id,
                }))
                }} 
                key={option.id} 
                selected={optionSelected === option.id}
              >
                {option.label}
              </S.MemberPreviewModalOption>
            ))}
          </S.MemberPreviewModalOptions>
          <MemberPreviewModalContent 
            member={member} 
            selected={optionSelected} 
            closeModal={closeModal}
            updateMemberNote={updateMemberNote} 
            addMemberDebt={addMemberDebt}
            sellSupplement={sellSupplement}
            memberships={memberships}
            supplements={supplements}
            resolveDebt={resolveDebt}
            editMember={editMember}
            extendMembershipBeforeEnd={extendMembershipBeforeEnd}
          />
        </Modal>
      )}
    </>
  );
}
 
export default MemberPreviewModal;