import * as S from "./styled/styledHeader";
import { IoAddCircleSharp } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import colors from "../../styles/colors";

const HeaderDesktop = ({ navigationLinks, selected, setAddMemberModal, logout }) => {
  return (
    <>
      <S.Header>
        <S.HeaderContainer>
          <S.HeaderNav>
            <S.HeaderUl>
              {navigationLinks.map((link, index) => (
                <S.HeaderLi key={index}>
                  <S.HeaderLink to={link.path} selected={selected === link.name}>
                    {link.name}
                  </S.HeaderLink>
                </S.HeaderLi>
              ))}
            </S.HeaderUl>
          </S.HeaderNav>
          <S.HeaderButtonContainer>
            <S.HeaderButton onClick={() => setAddMemberModal(true)}>
              <IoAddCircleSharp size={28} color={colors.white} />
              <S.HeaderButtonText>Додај Члана</S.HeaderButtonText>
            </S.HeaderButton>
            <S.HeaderButton onClick={() => logout()} right secondary>
              <BiLogOut size={28} color={colors.white} />
              <S.HeaderButtonText>Одјава</S.HeaderButtonText>
            </S.HeaderButton>
          </S.HeaderButtonContainer>
        </S.HeaderContainer>
      </S.Header>
    </>
  );
}
 
export default HeaderDesktop;