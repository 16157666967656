import axios from "../../util/axios-instance";
import { useState } from "react";
import { getTypeValue } from "../../util/app-util";
import Checkbox from "../Checkbox/Checkbox";
import * as Global from "../Global/styledWrapper";

const AddPayment = ({ fetchPaymentsInfo, fetchTodayPayments }) => {

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [state, setState] = useState({
    description: "",
    value: 0,
    membership: false,
    supplement: false,
    termin: false,
  })

  const { description, value, membership, supplement, termin } = state

  const submitAddPayment = () => {
    const payment = {
      addedBy: loggedInUser.username,
      value,
      description,
      type: getTypeValue(membership, supplement, termin)
    }
    axios.post('/payments/addPayment', payment).then(() => {
      fetchPaymentsInfo()
      fetchTodayPayments()
      setState(prevState => ({
        ...prevState,
        description: "",
        value: 0,
        membership: false,
        supplement: false,
        termin: false,
      }))
    })
  }

  return (
    <Global.PaymentsFlexColumn>
      <Global.ContainerHeading>Додавање у пазар</Global.ContainerHeading>
      <Global.ModalLabel>Опис</Global.ModalLabel>
      <Global.ModalTextInput
        value={description}
        placeholder="Унеси опис..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            description: e.target.value,
          }))
        }}
      />
      <Global.ModalLabel>Вредност</Global.ModalLabel>
      <Global.ModalTextInput
        value={value}
        placeholder="Унеси вредност..."
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            value: !isNaN(e.target.value) ? e.target.value : prevState.value,
          }))
        }}
      />
      <Checkbox 
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={membership}
        size={20}
        checkboxText="Чланарина"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !membership,
            supplement: !membership ? false : supplement,
            termin: !membership ? false : termin,
          }))
        }}
      />
      <Checkbox
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={supplement}
        size={20}
        checkboxText="Суплемент"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !supplement ? false : membership,
            supplement: !supplement,
            termin: !membership ? false : termin,
          }))
        }}
      />
      <Checkbox 
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={termin}
        size={20}
        checkboxText="Термин"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            membership: !termin ? false : membership,
            supplement: !termin ? false : supplement,
            termin: !termin,
          }))
        }}
      />
      <Global.SubmitButton onClick={() => submitAddPayment()}>Потврди</Global.SubmitButton>
    </Global.PaymentsFlexColumn>
  );
}
 
export default AddPayment;