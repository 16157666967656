import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HomeInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid ${colors.red};
  padding: 0 20px;
  margin: 20px 0;
  background-color: ${colors.darkGrey};
  @media only screen and (max-width: 600px) {
    background-color: unset;
    border: unset;
    border-radius: unset;
    padding: unset;
    margin: 5px 0 20px;
  }
`;

export const HomeInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.red};
  padding: 20px 10px;
  &:last-child{
    border-bottom: unset;
  }
  @media only screen and (max-width: 600px){
    flex-direction: column;
    border-bottom: unset;
    border: 2px solid ${colors.red};
    border-radius: 7px;
    background-color: ${colors.darkGrey};
    margin-top: 15px;
    padding: 20px;
    &:last-child{
      border-bottom: 2px solid ${colors.red};
    }
  }
`;

export const HomeInfoBoxLabel = styled.p`
  font-family: 'Balsamiq Bold';
  text-align: left;
  font-size: 24px;
  color: ${colors.white};
  @media only screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const HomeInfoBoxValue = styled.p`
  font-family: 'Balsamiq Regular';
  text-align: right;
  font-size: 24px;
  color: ${props => props.color};
  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: 26px;
  }
`;