import { useEffect, useState } from "react";
import { getYearOrMonthDisplay } from "../../util/app-util";
import * as S from "../PaymentsInfo/styled/styledPaymentsInfo";

const StatsInfo = ({ statsInfo }) => {
  const [state, setState] = useState({
    overallSum: statsInfo?.overallSum || 0, 
    overallSupplementsSum: statsInfo?.overallSupplementsSum || 0, 
    overallMembersCount: statsInfo?.overallMembersCount || 0, 
    year: statsInfo?.year || 0, 
    month: statsInfo?.month || 0, 
  })
  const { overallSum, overallSupplementsSum, overallMembersCount, year, month } = state;

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      overallSum: statsInfo?.overallSum || 0,  
      overallSupplementsSum: statsInfo?.overallSupplementsSum || 0, 
      overallMembersCount: statsInfo?.overallMembersCount || 0,
      year: statsInfo?.year || 0, 
      month: statsInfo?.month || 0, 
    }))
  }, [statsInfo])

  const data = [
    { label: `Укупан пазар за  ${getYearOrMonthDisplay(year, month)}`, value: overallSum, money: true },
    { label: `Укупан пазар од суплемената за ${getYearOrMonthDisplay(year, month)}`, value: overallSupplementsSum, money: true },
    { label: `Укупан број чланова за ${getYearOrMonthDisplay(year, month)}`, value: overallMembersCount, money: false },
  ]

  return (
    <S.HomeInfoContainer>
      {data.map((info, index) => (
        <S.HomeInfoBox key={index}>
          <S.HomeInfoBoxLabel>{info.label}</S.HomeInfoBoxLabel>
          <S.HomeInfoBoxValue money={info.money}>
            {info.money ? `${parseInt(info.value).toLocaleString()} РСД` : info.value}
          </S.HomeInfoBoxValue>
        </S.HomeInfoBox>
      ))}
    </S.HomeInfoContainer>
  );
}
 
export default StatsInfo;