import styled from 'styled-components';
import colors from '../../styles/colors';
import { IoCloseSharp } from "react-icons/io5"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  min-height: calc(100vh - 70px);
  background-color: ${colors.background};
  padding: 40px 70px;
  flex-direction: column;
  margin: 70px auto 0;
  @media only screen and (max-width: 600px) {
    padding: 20px 5%;
  }
`;

// Modal
export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBlack};
  z-index: 300000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
export const ModalBox = styled.div`
  width: 720px;
  background-color: ${colors.darkGrey};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 70px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    top: 0;
    border-radius: 0;
    position: fixed;
  }
`
export const ModalHeader = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`
export const ModalHeading = styled.p`
  font-size: 18px;
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
`
export const ModalClose = styled(IoCloseSharp)`
  color: ${colors.white};
  width: 26px;
  height: 26px;
  cursor: pointer;
`
export const ModalBody = styled.div`
  padding: 15px;
  border-top: 1px solid ${colors.thinWhiteLine};
  border-bottom: 1px solid ${colors.thinWhiteLine};
  height: 400px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    overflow-y: scroll;
    height: unset;
    flex: 1;
  }
`

export const ModalLabel = styled.label`
  font-size: 16px;
  color: ${colors.white};
  font-family: 'Balsamiq Bold';
  margin: 5px 0;
  align-self: flex-start;
  display: flex;
`

export const ModalTextInput = styled.input`
  width: calc(100% - 15px);
  background-color: ${colors.transparent};
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 16px;
  padding: 10px;
  resize: none;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 2px solid ${colors.white};
`

export const ModalTextArea = styled.textarea`
  width: 100%;
  max-height: 250px;
  height: 100%;
  background-color: ${colors.transparent};
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 16px;
  padding: 10px;
  resize: none;
  border-radius: 7px;
  margin-bottom: 10px;
`

export const MemberImageContainer = styled.label`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  align-self: center;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 2px 12px;
`

export const MemberImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  opacity: ${props => props.imageHovered ? "0.4" : "1"}
`

export const SubmitButton = styled.button`
  align-self: center;
  margin-top: auto;
  padding: 10px 15px;
  background-color: ${props => props.cancel ? colors.red : colors.green};
  border: none;
  border-radius: 7px;
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 18px;
  cursor: pointer;
`

export const Search = styled.input`
  margin-top: 40px;
  max-width: 500px;
  background-color: ${colors.transparent};
  border: 2px solid ${colors.red};
  border-radius: 7px;
  font-family: "Balsamiq Regular";
  font-size: 18px;
  padding: 10px;
  color: ${colors.white};
`;

export const SelectionContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Selection = styled.div`
  font-size: 24px;
  padding: 8px;
  background-color: ${props => props.selected ? colors.red : colors.header};
  border-radius: 8px;
  color: ${colors.white};
  font-family: 'Balsamiq Regular';
  cursor: pointer;
  margin-right: 7px;
  border: 2px solid ${colors.red};
  @media only screen and (max-width: 600px) {
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
`;

export const PaymentsFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0 25px 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PaymentsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  border: 2px solid ${colors.red};
  border-radius: 8px;
  padding: 15px; 
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const ContainerHeading = styled.p`
  font-size: 30px;
  font-family: 'Balsamiq Bold';
  color: ${colors.white};
  text-align: center;
  margin-bottom: 15px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: auto;
`;