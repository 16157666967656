import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    const updateWidth = debounce(
      () => {
        setWidth(window.innerWidth);
      },
      0,
      { maxWait: 200 }
    );
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
      updateWidth.cancel();
    };
  }, []);
  return width;
}

export default useWindowWidth;
