import styled from "styled-components"
import colors from "../../../styles/colors";

export const LoginContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 480px){
    background-color: ${colors.header};
    align-items: flex-start;
  }
`
export const LoginBox = styled.div`
  width: 500px;
  background-color: ${colors.header};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
`
export const LoginLabel = styled.label`
  font-size: 16px;
  color: ${colors.white};
  font-family: 'Balsamiq Bold';
  margin: 5px 0;
  align-self: flex-start;
  display: flex;
`
export const LoginInput = styled.input`
  width: 100%;
  background-color: ${colors.transparent};
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 16px;
  padding: 10px;
  resize: none;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 2px solid ${colors.white};
`
export const LoginButton = styled.button`
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  background-color: ${colors.red};
  border: none;
  border-radius: 7px;
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 18px;
  cursor: pointer;
  @media only screen and (max-width: 480px){
    width: 100%;
  }
`
export const LoginLogo = styled.img`
  width: 70%;
`
export const LoginError = styled.p`
  font-size: 18px;
  color: ${colors.red};
  font-family: 'Balsamiq Regular';
  margin: 5px 0;
`