import Modal from "../Modal";
import * as Global from "../Global/styledWrapper";
import { useEffect, useState } from "react";

const EditPaymentModal = ({ display, payment, closeModal, editPayment }) => {

  const [state, setState] = useState({
    description: payment?.description || "",
    value: payment?.value || 0,
    duration: payment?.duration || 0,
  })

  const { description, value, duration } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      description: payment?.description || "",
      value: payment?.value || 0,
      duration: payment?.duration || 0,
    }))
  }, [payment])

  const submitEditPayment = () => {
    const paymentEdit = {
      description,
      value,
      duration
    }
    editPayment(payment, paymentEdit)
    closeModal()
  }

  return (
    <>
      {display && (
        <Modal
          heading="Измена Уплате"
          closeModal={closeModal}
        >
          <Global.ModalLabel>Опис</Global.ModalLabel>
          <Global.ModalTextInput
            value={description}
            placeholder="Унеси опис..."
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))
            }}
          />
          <Global.ModalLabel>Вредност</Global.ModalLabel>
          <Global.ModalTextInput
            value={value}
            placeholder="Унеси вредност..."
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                value: !isNaN(e.target.value) ? e.target.value : prevState.value,
              }))
            }}
          />
          {payment.type === 1 && (
            <>
              <Global.ModalLabel>Број Дана</Global.ModalLabel>
              <Global.ModalTextInput
                value={duration}
                placeholder="Унеси вредност..."
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    duration: !isNaN(e.target.value) ? e.target.value : prevState.duration,
                  }))
                }}
              />
            </>
          )}
          <Global.SubmitButton onClick={() => submitEditPayment()}>Потврди</Global.SubmitButton>
        </Modal>
      )}
    </>
  );
}
 
export default EditPaymentModal;