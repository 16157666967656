import { useState } from "react"
import SingleSelect from "../SingleSelect"
import Checkbox from "../Checkbox/Checkbox"
import * as Global from "../Global/styledWrapper"

const SellSupplement = ({ member, sellSupplement, closeModal, supplements }) => {
  
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"))

  const [state, setState] = useState({
    selectedOption: null,
    paid: false,
  })

  const { selectedOption, paid } = state

  const getSupplementsOptions = () => {
    const supplementOptions = []
    supplements.map((sup) => (
      supplementOptions.push({ value: sup, label: `${sup.name} - ${sup.value} РСД` })
    ))
    return supplementOptions
  }

  const submitSellSupplement = () => {
    const supplementToSell = {
      addedBy: loggedInUser.username,
      value: selectedOption.value,
      duration: selectedOption.duration,
      description: selectedOption.name,
      type: selectedOption.type,
      paid,
    }
    sellSupplement(member, supplementToSell)
    setState((prevState) => ({
      ...prevState,
      selectedOption: null,
      paid: false
    }))
    closeModal()
  }

  return (
    <>
      <SingleSelect 
        options={getSupplementsOptions()} 
        handleSelection={(option) => { 
          setState((prevState) => ({
            ...prevState,
            selectedOption: option.value,
          }))
        }} 
      />
      <Checkbox
        style={{ marginTop: 5, padding: "10px 10px 10px 0" }}
        checked={paid}
        size={20}
        checkboxText="Плаћено"
        updateCheckbox={() => {
          setState((prevState) => ({
            ...prevState,
            paid: !paid,
          }))
        }} 
      />
      <Global.SubmitButton onClick={() => submitSellSupplement()}>Потврди</Global.SubmitButton>
    </>
  );
}
 
export default SellSupplement;