import Compressor from 'compressorjs';

export const MEMBERSHIP_TYPE = 1;
export const SUPPLEMENT_TYPE = 2;
export const TERMIN_TYPE = 3;

export const getHighestDatePayment = (member) => {
  return member.payments
    .filter(
      (payment) =>
        (payment.type === 1 || payment.type === 3) && payment.debt === false,
    )
    .reduce((a, b) => (a.endDate > b.endDate ? a : b));
};

export const getTypeValue = (membership, supplement, termin) => {
  if (membership) {
    return 1
  }
  if (supplement) {
    return 2
  }
  if (termin) {
    return 3
  }
}

export const compress = async (file, quality) => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      success: resolve,
      error: reject,
    });
  });
}

export const calculatePaymentsSum = (payments) => {
  return payments.reduce((n, { value }) => n + value, 0)
}

const months = [
  { value: 1, label: "Јануар" },
  { value: 2, label: "Фебруар" },
  { value: 3, label: "Март" },
  { value: 4, label: "Април" },
  { value: 5, label: "Мај" },
  { value: 6, label: "Јун" },
  { value: 7, label: "Јул" },
  { value: 8, label: "Август" },
  { value: 9, label: "Септембар" },
  { value: 10, label: "Октобар" },
  { value: 11, label: "Новембар" },
  { value: 12, label: "Децембар" },
]

export const getMonths = (year) => {
  let localMonths = months
  if (year === 2022) {
    localMonths = localMonths.filter((m) => m.value > 4)
  }
  if (year === new Date().getFullYear()) {
    return localMonths.filter((m) => m.value <= new Date().getMonth() + 1)
  }
  return localMonths
}

export const getYears = () => {
  const years = []
  const currentYear = new Date().getFullYear()
  for (let i = 2022; i <= currentYear; i++) {
    years.push({ value: i, label: i })
  }
  return years
}

export const getYearOrMonthDisplay = (year, month) => {
  if (month) {
    return `${months.find((m) => m.value === month).label} ${year}. године`
  }
  return `${year}. годину`
}
