import { getHighestDatePayment } from "../../util/app-util";
import * as S from "./styled/styledMemberPreviewModal";
import moment from "moment";
import _ from "lodash";
import colors from "../../styles/colors";
import { BsXCircleFill, BsCheckCircleFill } from "react-icons/bs";

const getPayments = (member) => {
  return member.payments.filter((payment) => 
    payment.debt === false && payment.paymentDate && payment.value > 0
  )
}

const getMembershipPayments = (member) => {
  return member.payments.filter((payment) => 
    payment.debt === false && payment.paymentDate && payment.value > 0 && (payment.type === 1 || payment.type === 3) 
  )
}

const getSupplementPayments = (member) => {
  return member.payments.filter((payment) => 
    payment.debt === false && payment.paymentDate && payment.value > 0 && payment.type === 2
  )
}

const getDebts = (member) => {
  return member.payments.filter((payment) => 
    payment.debt === true && payment.value > 0
  )
}

const getDebtsForSum = (member) => {
  return member.payments.filter((payment) => 
    payment.debt === true && payment.value > 0 && payment.paymentDate === null
  )
}

const getPaymentsSum = (payments) => {
  const values = payments.map((payment) => payment.value)
  return _.sum(values);
}

const centerButtonStyle = {
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "100%",
}

const Membership = ({ member, resolveDebt, closeModal }) => {

  const submitResolveDebt = (payment) => {
    resolveDebt(payment)
    closeModal()
  }

  return (
    <>
      <S.MembershipEnds>Чланарина истиче:&nbsp;<p style={{ color: colors.red }}>{moment(getHighestDatePayment(member).endDate).format('DD/MM/YYYY HH:mm')}</p></S.MembershipEnds>
      <S.MembershipsTable>
        <thead>
          <S.MembershipsTableRow head>
            <S.MembershipsTableTh>Датум Уплате</S.MembershipsTableTh>
            <S.MembershipsTableTh>Радник</S.MembershipsTableTh>
            <S.MembershipsTableTh>Број Дана</S.MembershipsTableTh>
            <S.MembershipsTableTh>Износ</S.MembershipsTableTh>
          </S.MembershipsTableRow>
        </thead>
        <tbody>
          {getMembershipPayments(member).map((payment) => (
            <S.MembershipsTableRow key={payment.id} head={false}>
              <S.MembershipsTableTd>{moment(payment.paymentDate).format('DD/MM/YYYY HH:mm')}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.addedBy}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.duration}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.value}</S.MembershipsTableTd>
            </S.MembershipsTableRow>
          ))}
        </tbody>
      </S.MembershipsTable>
      <S.MembershipsTable>
        <thead>
          <S.MembershipsTableRow head>
            <S.MembershipsTableTh>Датум Уплате</S.MembershipsTableTh>
            <S.MembershipsTableTh>Радник</S.MembershipsTableTh>
            <S.MembershipsTableTh>Назив</S.MembershipsTableTh>
            <S.MembershipsTableTh>Износ</S.MembershipsTableTh>
          </S.MembershipsTableRow>
        </thead>
        <tbody>
          {getSupplementPayments(member).map((payment) => (
            <S.MembershipsTableRow key={payment.id} head={false}>
              <S.MembershipsTableTd>{moment(payment.paymentDate).format('DD/MM/YYYY HH:mm')}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.addedBy}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.description}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.value}</S.MembershipsTableTd>
            </S.MembershipsTableRow>
          ))}
        </tbody>
      </S.MembershipsTable>
      <S.OverallPaid>Укупно уплаћено:&nbsp;<p style={{ color: colors.green }}>{getPaymentsSum(getPayments(member)).toLocaleString()}</p></S.OverallPaid>
      <S.MembershipsTable>
        <thead>
          <S.MembershipsTableRow head>
            <S.MembershipsTableTh>Датум</S.MembershipsTableTh>
            <S.MembershipsTableTh>Назив</S.MembershipsTableTh>
            <S.MembershipsTableTh>Статус</S.MembershipsTableTh>
            <S.MembershipsTableTh>Износ</S.MembershipsTableTh>
          </S.MembershipsTableRow>
        </thead>
        <tbody>
          {getDebts(member).map((payment) => (
            <S.MembershipsTableRow key={payment.id} head={false}>
              <S.MembershipsTableTd>{moment(payment.startDate).format('DD/MM/YYYY HH:mm')}</S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.description}</S.MembershipsTableTd>
              <S.MembershipsTableTd>
                {payment.paymentDate ? (
                  <div style={centerButtonStyle}>
                    <BsCheckCircleFill color={colors.green} size={22} />
                  </div>
                ) : (
                  <div style={centerButtonStyle}>
                    <BsXCircleFill style={{ cursor: "pointer" }} onClick={() => submitResolveDebt(payment)} color={colors.red} size={22} />
                  </div>
                )}
              </S.MembershipsTableTd>
              <S.MembershipsTableTd>{payment.value}</S.MembershipsTableTd>
            </S.MembershipsTableRow>
          ))}
        </tbody>
      </S.MembershipsTable>
      <S.OverallPaid>Укупан дуг:&nbsp;<p style={{ color: colors.red }}>{getPaymentsSum(getDebtsForSum(member)).toLocaleString()}</p></S.OverallPaid>
    </>
  );
}
 
export default Membership;