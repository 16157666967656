/* eslint-disable react-hooks/exhaustive-deps */
import { MEMBERSHIP_TYPE } from "../../util/app-util";
import * as Global from "../Global/styledWrapper"
import Modal from "../Modal";
import Checkbox from "../Checkbox/Checkbox";
import { useEffect, useState } from "react";

const getTypeToSubmit = (type, termin) => {
  if (type === 1) {
    return termin ? 3 : 1
  }
  if (type === 2) {
    return 2
  }
}

const PriceModal = ({ display, type, item, closeModal, addPrice, editPrice }) => {
  const [state, setState] = useState({
    name: item?.name || "",
    duration: item?.duration || 0,
    value: item?.value || 0,
    termin: item?.type === 3 ? true : false,
  })

  const { name, duration, value, termin } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      name: item?.name || "",
      duration: item?.duration || 0,
      value: item?.value || 0,
      termin: item?.type === 3 ? true : false,
    }))
  }, [display])

  const submitAddPrice = () => {
    const addPriceBody = {
      name,
      duration,
      value,
      type: getTypeToSubmit(type, termin),
    }
    if (item) {
      editPrice(item, addPriceBody)
    } else {
      addPrice(addPriceBody)
    }
    closeModal()
  }

  return (
    <>
      {display && (
        <Modal 
          heading={item ? item.name : `Додај ${type === MEMBERSHIP_TYPE ? "Чланарину" : "Суплемент"}`} 
          closeModal={closeModal}
        >
          <Global.ModalLabel>Назив</Global.ModalLabel>
          <Global.ModalTextInput
            value={name}
            placeholder="Унеси назив..."
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }}
          />
          {type === MEMBERSHIP_TYPE && (
            <>
              <Global.ModalLabel>Број Дана</Global.ModalLabel>
              <Global.ModalTextInput
                value={duration}
                placeholder="Унеси број дана..."
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    duration: !isNaN(e.target.value) ? e.target.value : prevState.duration,
                  }))
                }}
              />
            </>
          )}
          <Global.ModalLabel>Цена</Global.ModalLabel>
          <Global.ModalTextInput
            value={value}
            placeholder="Унеси цену..."
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                value: !isNaN(e.target.value) ? e.target.value : prevState.value,
              }))
            }}
          />
          {type === MEMBERSHIP_TYPE && (
            <>
              <Checkbox
                style={{ marginTop: 5, padding: "5px 10px 15px 0" }}
                checked={termin}
                size={20}
                checkboxText="Термин (Све означено као термин не шаље поруку приликом истека)"
                updateCheckbox={() => {
                  setState((prevState) => ({
                    ...prevState,
                    termin: !termin,
                  }))
                }} 
              />
            </>
          )}
          <Global.SubmitButton onClick={() => submitAddPrice()}>Потврди</Global.SubmitButton>
        </Modal>
      )}
    </>
  );
}
 
export default PriceModal;