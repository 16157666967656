/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header/Header";
import * as Global from "../../components/Global/styledWrapper";
import * as S from "../../components/Price/styled/styledPrices";
import { useEffect, useState } from "react";
import MembershipPrices from "../../components/Price/MembershipPrices";
import SupplementPrices from "../../components/Price/SupplementPrices";
import colors from "../../styles/colors";
import { IoAddCircleSharp } from "react-icons/io5";
import { MEMBERSHIP_TYPE, SUPPLEMENT_TYPE } from "../../util/app-util";
import PriceModal from "../../components/Price/PriceModal";
import axios from "../../util/axios-instance";
import { useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const options = [
  { id: 1, label: "Чланарине"},
  { id: 2, label: "Суплементи"},
]

const Prices = () => {
  const history = useHistory();

  const [state, setState] = useState({
    priceModal: { display: false, type: null, item: null },
    optionSelected: 1,
    memberships: [],
    supplements: [],
    loading: false,
  })
  
  const { priceModal, optionSelected, memberships, supplements, loading } = state

  const fetchMemberships = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    axios.get('/prices/memberships')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        memberships: res.data,
        loading: false,
      }))
    })
  }

  const fetchSupplements = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }))
    axios.get('/prices/supplements')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        supplements: res.data,
        loading: false,
      }))
    })
  }

  const deletePrice = (price) => {
    axios.delete(`/prices/${price.id}`)
    .then(res => {
      fetchMemberships()
      fetchSupplements()
    })
  }

  const addPrice = (addPriceBody) => {
    axios.post(`/prices`, addPriceBody)
    .then(res => {
      fetchMemberships()
      fetchSupplements()
    })
  }

  const editPrice = (price, editPriceBody) => {
    axios.patch(`/prices/${price.id}`, editPriceBody)
    .then(res => {
      fetchMemberships()
      fetchSupplements()
    })
  }

  useEffect(() => {
    document.title = "Alpha Gym - Цене"
    if (!JSON.parse(sessionStorage.getItem("loggedInUser"))) {
      history.push('/login');
    } else {
      fetchMemberships()
      fetchSupplements()
    }
  }, []);

  const openPriceModal = (type, item) => {
    setState((prevState) => ({
      ...prevState,
      priceModal: { display: true, type, item },
    }))
  }

  const closePriceModal = () => {
    setState((prevState) => ({
      ...prevState,
      priceModal: { display: false, type: null, item: null },
    }))
  }

  return (
    <>
      <Header selected="Цене" memberships={memberships} />
      <PriceModal 
        display={priceModal.display}
        type={priceModal.type}
        item={priceModal.item}
        closeModal={closePriceModal}
        addPrice={addPrice}
        editPrice={editPrice}
      />
      <Global.Wrapper>
        <Global.SelectionContainer>
          {options.map((option) => (
            <Global.Selection
              onClick={() => {
              setState((prevState) => ({
                ...prevState,
                optionSelected: option.id,
              }))
              }} 
              key={option.id} 
              selected={optionSelected === option.id}
            >
              {option.label}
            </Global.Selection>
          ))}
        </Global.SelectionContainer>
        {loading ? (
          <Oval width={80} height={80} color={colors.white} secondaryColor={colors.lightGrey} strokeWidth={7} />
        ) : (
          <>
            {optionSelected === 1 && <MembershipPrices openModal={openPriceModal} memberships={memberships} deletePrice={deletePrice} />}
            {optionSelected === 2 && <SupplementPrices openModal={openPriceModal} supplements={supplements} deletePrice={deletePrice} />}
            <S.PricesAdd onClick={() => openPriceModal(optionSelected === 1 ? MEMBERSHIP_TYPE : SUPPLEMENT_TYPE, null)}>
              <IoAddCircleSharp size={28} color={colors.white} />
              <S.PriceAddText>{`Додај ${optionSelected === 1 ? "Чланарину" : "Суплемент"}`}</S.PriceAddText>
            </S.PricesAdd>
          </>
        )}

      </Global.Wrapper>
    </>
  );
}
 
export default Prices;