import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import colors from "../../styles/colors";
import * as S from "./styled/styledHomeInfo";

const HomeInfo = ({ homePageInfo, credits }) => {
  const [state, setState] = useState({
    // allMembersCount: homePageInfo.data?.allMembersCount || 0, 
    activeMembersCount: homePageInfo.data?.activeMembersCount || 0, 
    inactiveMembersCount: homePageInfo.data?.inactiveMembersCount || 0, 
    overallPaymentsThisMonth: homePageInfo.data?.overallPaymentsThisMonth || 0, 
    overallDebt: homePageInfo.data?.overallDebt || 0,
    creditsValue: credits.data?.total || 0,
    creditsValid: credits.data?.valid_until || "",
  })
  const { 
    // allMembersCount, 
    activeMembersCount, 
    inactiveMembersCount, 
    overallPaymentsThisMonth, 
    overallDebt,
    creditsValue,
    creditsValid
  } = state;

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      // allMembersCount: homePageInfo.data?.allMembersCount || 0, 
      activeMembersCount: homePageInfo.data?.activeMembersCount || 0, 
      inactiveMembersCount: homePageInfo.data?.inactiveMembersCount || 0, 
      overallPaymentsThisMonth: homePageInfo.data?.overallPaymentsThisMonth || 0, 
      overallDebt: homePageInfo.data?.overallDebt || 0,
      creditsValue: credits.data?.total || 0,
      creditsValid: credits.data?.valid_until || "",
    }))
  }, [homePageInfo, credits])

  const loggedInUsername = JSON.parse(sessionStorage.getItem("loggedInUser"))?.username;

  const data = [
    // { label: "Укупно чланова", value: allMembersCount, color: colors.blue, money: false },
    { label: "Активних чланова", value: activeMembersCount, color: colors.green, money: false },
    { label: "Неактивних чланова", value: inactiveMembersCount, color: colors.red, money: false },
    { label: "Укупан пазар за овај месец", value: loggedInUsername === 'brle' ? overallPaymentsThisMonth : '///', color: colors.green, money: true },
    { label: "Укупан дуг", value: overallDebt, color: colors.red, money: true },
  ]

  const creditsToDisplay = {
    label: "Кредит", value: creditsValue, creditsValid: creditsValid, color: colors.blue
  }

  return (
    <S.HomeInfoContainer>
      {data.map((info, index) => (
        <S.HomeInfoBox key={index} color={info.color}>
          <S.HomeInfoBoxLabel>{info.label}</S.HomeInfoBoxLabel>
          {homePageInfo.loading ? (
            <Oval width={24} height={24} color={info.color} strokeWidth={7} />
          ) : (
            <S.HomeInfoBoxValue color={info.color}>
              {info.money ? `${parseInt(info.value).toLocaleString()} РСД` : info.value}
            </S.HomeInfoBoxValue>
          )}

        </S.HomeInfoBox>
      ))}
      <S.HomeInfoBox color={creditsToDisplay.color}>
        <S.HomeInfoBoxLabel>{creditsToDisplay.label}</S.HomeInfoBoxLabel>
        {credits.loading ? (
          <Oval width={24} height={24} color={creditsToDisplay.color} strokeWidth={7} />
        ) : (
          <S.HomeInfoBoxValue color={creditsToDisplay.color}>
            {`${creditsToDisplay.value} | Важи до ${creditsToDisplay.creditsValid}`}
          </S.HomeInfoBoxValue>
        )}
      </S.HomeInfoBox>
    </S.HomeInfoContainer>
  );
}
 
export default HomeInfo;