import styled from 'styled-components';
import colors from '../../../styles/colors';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { CgNotes } from "react-icons/cg";
import dummyMember from "../../../images/dummyMember.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
// FaLockOpen
const cardWidth = "270px";

export const MemberContainer = styled.div`
  display: grid;
  width: 100%;
  margin-top: 40px;
  grid-auto-rows: auto;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(auto-fill,minmax(${cardWidth},1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-auto-rows: unset;
    grid-auto-columns: unset;
    grid-auto-columns: unset;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
    flex-wrap: wrap;
  }
`;

export const MemberContainerPagination = styled(InfiniteScroll)`
  display: grid;
  width: 100%;
  margin-top: 40px;
  grid-auto-rows: auto;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(auto-fill,minmax(${cardWidth},1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-auto-rows: unset;
    grid-auto-columns: unset;
    grid-auto-columns: unset;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
    flex-wrap: wrap;
  }
`;

export const memberContainerHeading = styled.p`
  width: 100%;
  text-align: left;
  color: ${colors.white};
  font-size: 32px;
  font-family: 'Balsamiq Bold';
  padding: 15px;
  text-transform: uppercase;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 500px;
    left: 0;
    bottom: 0;
    height: 3px;
    background-color: ${colors.red};
  };
  @media only screen and (max-width: 600px) {
    text-align: center;
    &:after{
      width: 100%;
    }
  }
`;

export const MemberCardContainer = styled.div`
  display: flex;
  width: ${cardWidth};
  height: 400px;
  border-radius: 8px;
  border: 2px solid ${colors.red};
  position: relative;
  @media only screen and (max-width: 600px) {
    margin: 20px 10px;
  }
`;

export const MemberCardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.memberImage ? `https://api.alphagymapp.rs/${props.memberImage}` : dummyMember});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  border-radius: 6px;
`;

export const MemberCardId = styled.p`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px 8px 2px 8px;
  font-family: 'Balsamiq Bold';
  color: ${colors.white};
  background-color: ${colors.blue};
  z-index: 2;
  border-radius: 5px;
  user-select: none;
`;

export const MemberCardName = styled.p`
  position: absolute;
  bottom: 50px;
  font-family: 'Balsamiq Regular';
  font-size: 22px;
  color: ${colors.white};
  z-index: 2;
  border-radius: 5px;
  user-select: none;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
  text-align: center;
  max-width: 90%;
`;

export const MemberCardPreview = styled.p`
  position: absolute;
  bottom: 10px;
  padding: 9px 15px 7px 15px;
  font-family: 'Balsamiq Bold';
  font-size: 16px;
  color: ${colors.header};
  background-color: ${colors.white};
  z-index: 2;
  border-radius: 8px;
  user-select: none;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
`;

export const MemberCardRenew = styled.p`
  position: absolute;
  top: 10px;
  padding: 4px 8px 2px 8px;
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
  background-color: ${colors.red};
  z-index: 2;
  border-radius: 8px;
  user-select: none;
  right: 10px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
`;

export const MemberCardDate = styled.p`
  position: absolute;
  top: 45px;
  padding: 4px 8px 2px 8px;
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
  background-color: ${colors.red};
  z-index: 2;
  border-radius: 8px;
  user-select: none;
  right: 10px;
  width: fit-content;
  text-align: center;
  opacity: 0.5;
`;

export const MemberCardLock = styled(FaLock)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: ${colors.gold};
  width: 25px;
  height: 25px;
  z-index: 2;
  cursor: pointer;
`

export const MemberCardLockOpen = styled(FaLockOpen)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: ${colors.gold};
  width: 25px;
  height: 25px;
  z-index: 2;
  cursor: pointer;
`

export const MemberCardNote = styled(CgNotes)`
  width: 26px;
  height: 26px;
  color: ${colors.gold};
  position: absolute;
  top: 50px;
  left: 10px;
`

export const MemberResetDateButton = styled.div`
  padding: 6px 8px;
  border-radius: 7px;
  background-color: ${colors.red};
  cursor: pointer;
  border: 2px solid ${colors.red};
  align-self: flex-start;
  margin-top: 10px;
  color: ${colors.white};
  font-family: 'Balsamiq Bold';
`