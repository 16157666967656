import styled from 'styled-components';
import colors from '../../../styles/colors';
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";

export const Header = styled.header`
  z-index: 10000;
  background-color: ${colors.header};
  width: 100%;
  position: fixed;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 70px;
  margin: auto;
  @media only screen and (max-width: 992px) {
    align-items: center;
    padding: 0 60px 0 70px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 5%;
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
`;

export const HeaderUl = styled.ul`
  list-style: none;
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderLi = styled.li`
  display: flex;
  @media only screen and (max-width: 992px) {
    width: 90%;
  }
`;

export const HeaderLink = styled(Link)`
  color: ${props => props.selected ? colors.white : colors.lightGrey};
  position: relative;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  padding: 25px 20px 24px 20px;
  cursor: pointer;
  font-family: 'Balsamiq Bold';
  &:hover {
    color: ${colors.white};
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: ${props => props.selected ? "2px" : "0"} solid ${colors.red};
    border-top: ${props => props.selected ? "2px" : "0"} solid ${colors.red};
    border-radius: 3px 3px 0 0;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    border ${props => props.selected ? "2px" : "0px"} solid ${colors.red};
    border-radius: 7px;
    padding: 20px;
    &::after {
      border-top: 0px;
      border-bottom: 0px;
    }
  }
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 992px) {
    margin-top: 15px;
  }
`;

export const HeaderButton = styled.div`
  display: flex;
  padding: 6px 8px;
  border-radius: 7px;
  background-color: ${props => props.secondary ? colors.header : colors.red};
  align-self: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid ${colors.red};
  ${props => props.right && `
    margin-left: 8px;
  `}
`;

export const HeaderButtonText = styled.div`
  color: ${colors.white};
  font-size: 16px;
  font-family: 'Balsamiq Bold';
  margin-left: 5px;
`;

export const HeaderMobileLogo = styled.img`
  width: 80px;
  padding: 4px 0;
  @media only screen and (max-width: 480px) {
    width: 70px;
  }
`;

export const HeaderMobileMenuButton = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const HeaderMobileMenuIcon = styled(FiMenu)`
  width: 40px;
  height: 40px;
  color: ${colors.white};
`;

export const HeaderMobileMenuOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalBlack};
  z-index: 200000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  transition: transform ${props => props.open ? "0s" : ".5s"} ease;
  transform: ${props => props.open ? "translateX(0%)" : "translateX(100%)"};
  @media only screen and (max-width: 480px) {
    background-color: ${colors.transparent};
  }
`;

export const HeaderMobileMenu = styled.div`
  position: relative;
  width: 480px;
  height: 100%;
  background-color: ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid ${colors.red};
  transition: transform .5s ease;
  transform: ${props => props.open ? "translateX(0%)" : "translateX(100%)"};
  @media only screen and (max-width: 480px) {
    width: 100%;
    border-left: unset;
  }
`;

export const HeaderMobileMenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 5%;
`;

export const HeaderMobileMenuClose = styled(IoCloseSharp)`
  color: ${colors.white};
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const HeaderMobileMenuBody = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;