import GlobalNote from "./GlobalNote";
import * as S from "./styled/styledGlobalNote"
import { IoAddCircleSharp } from "react-icons/io5"
import colors from "../../styles/colors";
import { Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";

const GlobalNoteContainer = ({ openModal, notes, deleteNote }) => {
  const [state, setState] = useState({
    notesData: notes.data || [],
  });

  const { notesData } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      notesData: notes.data || [],
    }))
  }, [notes])
  return (
    <>
      <S.GlobalNoteHeading>Белешке</S.GlobalNoteHeading>
      <S.GlobalNoteAdd onClick={() => openModal()}>
        <IoAddCircleSharp size={28} color={colors.white} />
        <S.GlobalNoteAddText>Додај Белешку</S.GlobalNoteAddText>
      </S.GlobalNoteAdd>
      <S.GlobalNoteContainer>
        {notes.loading ? (
          <Oval width={80} height={80} color={colors.white} secondaryColor={colors.lightGrey} strokeWidth={7} />
        ) : (
          <>
            {notesData.map((note) => (
              <GlobalNote deleteNote={deleteNote} note={note} key={note.id} />
            ))}
          </>
        )}
      </S.GlobalNoteContainer>
    </>
  );
}
 
export default GlobalNoteContainer;