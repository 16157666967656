import * as Global from "../Global/styledWrapper";
import Datetime from 'react-datetime';
import moment from 'moment';
import { useState } from "react";

const PaymentsRangePicker = ({ resetPaymentsRange, submitPaymentsRange }) => {

  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date()
  })

  const { startDate, endDate } = state

  const resetDates = () => {
    resetPaymentsRange()
    setState(prevState => ({
      ...prevState,
      startDate: new Date(),
      endDate: new Date(),
    }))
  }

  const submitDates = () => {
    submitPaymentsRange(new Date(moment(startDate).format("YYYY/MM/DD")), new Date(moment(endDate).format("YYYY/MM/DD")))
  }

  return (
    <Global.PaymentsFlexColumn>
      <Global.ContainerHeading>Опсег датума за приказ уплата</Global.ContainerHeading>
      <Global.ModalLabel>Од</Global.ModalLabel>
      <Datetime
        dateFormat={moment(startDate).format("DD/MM/YYYY")}
        timeFormat={false}
        className="input"
        required
        value={startDate}
        onChange={(e) => setState((prevState) => ({
          ...prevState,
          startDate: e._d,
        }))}
      />
      <Global.ModalLabel>До</Global.ModalLabel>
      <Datetime
        dateFormat={moment(endDate).format("DD/MM/YYYY")}
        timeFormat={false}
        className="input"
        required
        value={endDate}
        onChange={(e) => setState((prevState) => ({
          ...prevState,
          endDate: e._d,
        }))}
      />
      <Global.ButtonsContainer>
        <Global.SubmitButton onClick={() => submitDates()}>Потврди</Global.SubmitButton>
        <Global.SubmitButton cancel onClick={() => resetDates()}>Поништи</Global.SubmitButton>
      </Global.ButtonsContainer>
    </Global.PaymentsFlexColumn>
  );
}
 
export default PaymentsRangePicker;