import styled from "styled-components"
import colors from "../../../styles/colors"
import { AiFillEdit, AiFillDelete } from "react-icons/ai"

export const PricesTable = styled.table`
  width: 100%;
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
  text-align: left;
  border: 1px solid ${colors.red};
  border-collapse: collapse;
  margin-top: 15px;
`
export const PricesTableRow = styled.tr`
  background-color: ${colors.darkGrey};
  border-top: 1px solid ${colors.red};
  &:nth-child(odd){
    background-color: ${props => !props.head && colors.header};
  }
`
export const PricesTableTh = styled.th`
  padding: 15px 12px 13px 12px;
  font-size: 20px;
  @media only screen and (max-width: 600px) {
    padding: 12px 9px 10px 9px;
    font-size: 16px;
  }
`
export const PricesTableTd = styled.td`
  padding: 15px 12px 13px 12px;
  color: ${props => props.price ? colors.green : colors.white};
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: 600px) {
    padding: 12px 9px 10px 9px;
    font-size: 16px;
    hyphens: auto;
  }
`
export const PricesTableButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PricesEditButton = styled(AiFillEdit)`
  color: ${colors.gold};
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-right: 12px;
  @media only screen and (max-width: 600px) {
    margin-right: 7px;
  }
`
export const PricesDeleteButton = styled(AiFillDelete)`
  color: ${colors.red};
  width: 26px;
  height: 26px;
  cursor: pointer;
`

export const PricesAdd = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 7px;
  background-color: ${colors.red};
  margin-top: 20px;
  align-self: center;
  align-items: center;
  cursor: pointer;
`;

export const PriceAddText = styled.div`
  color: ${colors.white};
  font-size: 18px;
  font-family: 'Balsamiq Bold';
  margin-left: 5px;
`;