import MemberCard from "./MemberCard";
import * as S from "./styled/styledMember";
import * as Global from "../Global/styledWrapper"
import { Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";
import colors from "../../styles/colors";

const MemberContainer = ({
  members,
  heading,
  openPreviewModal,
  openMembershipExtendModal,
  searchValue,
  searchMembers,
  expiredMembers,
  updateMemberActive,
  pagination,
  getInactiveMembers,
}) => {
  const [membersState, setMembersState] = useState(members.data || []);
  const [page, setPage] = useState(members?.page || 1);
  const [total, setTotal] = useState(members?.total || 0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setMembersState(members?.data || [])
    setTotal(members?.total || 0)
  }, [members])

  const fetchMoreData = () => {
    if (membersState.length >= total) {
      setHasMore(false)
      return;
    }
    setPage(page + 1)
    getInactiveMembers(searchValue, page + 1)
  }
  return (
    <>
      {heading && <S.memberContainerHeading>{heading}</S.memberContainerHeading>}
      <Global.Search 
        placeholder="Претрага..." 
        value={searchValue} 
        onChange={(e) => {
          searchMembers(e.target.value)
          setPage(1)
          setHasMore(true)
        }}  
      />
      {members.loading && page === 1 ? (
        <Oval width={80} height={80} color={colors.white} secondaryColor={colors.lightGrey} strokeWidth={7} />
      ) : (
        <>
          {pagination ? (
            <S.MemberContainerPagination
              dataLength={membersState.length}
              next={() => fetchMoreData()}
              hasMore={hasMore}
              loader={<Oval width={80} height={80} color={colors.white} secondaryColor={colors.lightGrey} strokeWidth={7} />}
            >
              {membersState.map((member) => {
                return (
                  <MemberCard 
                    key={member.id}
                    expiredMembers={expiredMembers}
                    openPreviewModal={openPreviewModal}
                    openMembershipExtendModal={openMembershipExtendModal}
                    member={member}
                    updateMemberActive={updateMemberActive}
                  />
                );
              })}
            </S.MemberContainerPagination>
          ) : (
            <S.MemberContainer>
              {membersState.map((member) => {
                return (
                  <MemberCard 
                    key={member.id}
                    expiredMembers={expiredMembers}
                    openPreviewModal={openPreviewModal}
                    openMembershipExtendModal={openMembershipExtendModal}
                    member={member}
                    updateMemberActive={updateMemberActive}
                  />
                );
              })}
            </S.MemberContainer>
          )}

        </>
      )}
    </>
  );
}
 
export default MemberContainer;