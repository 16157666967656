import { MEMBERSHIP_TYPE } from "../../util/app-util";
import * as S from "./styled/styledPrices"

const MembershipPrices = ({ memberships, openModal, deletePrice }) => {
  return (
    <S.PricesTable>
      <thead>
        <S.PricesTableRow head>
          <S.PricesTableTh>Назив</S.PricesTableTh>
          <S.PricesTableTh>Број Дана</S.PricesTableTh>
          <S.PricesTableTh>Цена</S.PricesTableTh>
          <S.PricesTableTh></S.PricesTableTh>
        </S.PricesTableRow>
      </thead>
      <tbody>
        {memberships.map((membership) => (
          <S.PricesTableRow key={membership.id} head={false}>
            <S.PricesTableTd>{membership.name}</S.PricesTableTd>
            <S.PricesTableTd>{membership.duration}</S.PricesTableTd>
            <S.PricesTableTd price>{membership.value}</S.PricesTableTd>
            <S.PricesTableTd>
              <S.PricesTableButtons>
                <S.PricesEditButton onClick={() => openModal(MEMBERSHIP_TYPE, membership)} />
                <S.PricesDeleteButton onClick={() => deletePrice(membership)} />
              </S.PricesTableButtons>
            </S.PricesTableTd>
          </S.PricesTableRow>
        ))}
      </tbody>
    </S.PricesTable>
  );
}
 
export default MembershipPrices;