import moment from "moment";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import colors from "../../styles/colors";
import { calculatePaymentsSum } from "../../util/app-util";
import * as S from "./styled/styledPaymentsRange"

const PaymentsRangeDisplay = ({ paymentsRange, openPaymentModal, paymentsRangePicker }) => {

  const [state, setState] = useState({
    payments: paymentsRange.data || [],
  })

  const { payments } = state

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      payments: paymentsRange.data || [],
    }))
  }, [paymentsRange])

  const headingText = () => {
    if (paymentsRangePicker.startDate.valueOf() === paymentsRangePicker.endDate.valueOf()) {
      if (moment(paymentsRangePicker.startDate).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
        return "Данашње уплате"
      }
      return `Уплате за дан ${moment(paymentsRangePicker.startDate).format("DD/MM/YYYY")}`
    }
    return `Уплате од ${moment(paymentsRangePicker.startDate).format("DD/MM/YYYY")} до ${moment(paymentsRangePicker.endDate).format("DD/MM/YYYY")}`
  }

  return (
    <>
      <S.PaymentsRangeHeading>
        {headingText()}
      </S.PaymentsRangeHeading>
      {paymentsRange.loading ? (
        <Oval 
          width={80} 
          height={80} 
          color={colors.white} 
          secondaryColor={colors.lightGrey} 
          strokeWidth={7} 
          wrapperStyle={{ justifyContent: 'center', marginTop: 15 }} />
      ) : (
        <S.PricesTable>
          <thead>
            <S.PricesTableRow head>
              <S.PricesTableTh>Време Уплате</S.PricesTableTh>
              <S.PricesTableTh>Опис</S.PricesTableTh>
              <S.PricesTableTh>Износ</S.PricesTableTh>
              <S.PricesTableTh></S.PricesTableTh>
            </S.PricesTableRow>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <S.PricesTableRow key={payment.id} head={false}>
                <S.PricesTableTd>{moment(payment.paymentDate).format('DD/MM/YYYY HH:mm')}</S.PricesTableTd>
                <S.PricesTableTd>{payment.description}</S.PricesTableTd>
                <S.PricesTableTd price>{payment.value}</S.PricesTableTd>
                <S.PricesTableTd>
                  <S.PricesTableButtons>
                    <S.PricesEditButton onClick={() => openPaymentModal(payment)} />
                  </S.PricesTableButtons>
                </S.PricesTableTd>
              </S.PricesTableRow>
            ))}
            <S.PricesTableRow>
              <S.PricesTableTd>Укупно уплаћено</S.PricesTableTd>
              <S.PricesTableTd></S.PricesTableTd>
              <S.PricesTableTd price>{calculatePaymentsSum(payments)}</S.PricesTableTd>
              <S.PricesTableTd></S.PricesTableTd>
            </S.PricesTableRow>
          </tbody>
        </S.PricesTable>
      )}
    </>
  );
}
 
export default PaymentsRangeDisplay;