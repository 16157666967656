import styled from "styled-components"
import colors from "../../../styles/colors";

export const Label = styled.label`
  font-size: 16px;
  color: ${colors.white};
  font-family: 'Balsamiq Bold';
  margin: 5px 0;
  align-self: flex-start;
  display: flex;
`

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`

export const DisplayStatsButton = styled.button`
  align-self: center;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: ${colors.red};
  border: none;
  border-radius: 7px;
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 18px;
  cursor: pointer;
`