import styled from 'styled-components';
import colors from '../../../styles/colors';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
`;

export const Checkbox = styled.div`
  border: 2px solid ${props => props.checked ? colors.red : colors.white};
  background-color: ${props => props.checked ? colors.red : colors.transparent};
  border-radius: 3px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxText = styled.p`
  font-family: 'Balsamiq Regular';
  color: ${colors.white};
  margin-left: 10px;
  flex: 1;
`;