/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header/Header";
import * as Global from "../../components/Global/styledWrapper";
import AddPayment from "../../components/AddPayment";
import PaymentsInfo from "../../components/PaymentsInfo";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../util/axios-instance";
import PaymentsRangeDisplay from "../../components/PaymentsRange/PaymentsRangeDisplay";
import EditPaymentModal from "../../components/EditPaymentModal";
import PaymentsRangePicker from "../../components/PaymentsRange/PaymentsRangePicker";

const Payments = () => {
  const history = useHistory();
  const [state, setState] = useState({
    paymentsInfo: { data: [], loading: false, error: "" },
    memberships: [],
    paymentsRange: { data: [], loading: false, error: "" },
    editPaymentModal: { display: false, payment: null },
    paymentsRangePicker: { startDate: new Date(), endDate: new Date() }
  })

  const { paymentsInfo, memberships, paymentsRange, editPaymentModal, paymentsRangePicker } = state

  const fetchPaymentsInfo = () => {
    setState(prevState => ({
      ...prevState,
      paymentsInfo: { data: prevState.paymentsInfo.data, loading: true, error: prevState.paymentsInfo.error }
    }))
    axios.get('/payments/paymentsInfo')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        paymentsInfo: { data: res.data, loading: false, error: prevState.paymentsInfo.error }
      }))
    })
  }

  const fetchMemberships = () => {
    axios.get('/prices/memberships')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        memberships: res.data,
      }))
    })
  }

  const fetchTodayPayments = () => {
    setState(prevState => ({
      ...prevState,
      paymentsRange: { data: prevState.paymentsRange.data, loading: true, error: prevState.paymentsRange.error }
    }))
    axios.get('/payments/todayPayments')
    .then(res => {
      setState(prevState => ({
        ...prevState,
        paymentsRange: { data: res.data, loading: false, error: prevState.paymentsRange.error }
      }))
    })
  }

  const editPayment = (payment, paymentEdit) => {
    axios.patch(`/payments/updatePayment/${payment.id}`, paymentEdit)
    .then(res => {
      fetchTodayPayments()
    })
  }

  const fetchPaymentsRange = (startDate, endDate) => {
    setState(prevState => ({
      ...prevState,
      paymentsRange: { data: prevState.paymentsRange.data, loading: true, error: prevState.paymentsRange.error }
    }))
    axios.get(`/payments/paymentsRange/${startDate.valueOf()}/${endDate.valueOf()}`)
    .then(res => {
      setState(prevState => ({
        ...prevState,
        paymentsRange: { data: res.data, loading: false, error: prevState.paymentsRange.error }
      }))
    })
  }

  useEffect(() => {
    document.title = "Alpha Gym - Пазар"
    if (!JSON.parse(sessionStorage.getItem("loggedInUser"))) {
      history.push('/login');
    } else {
      fetchPaymentsInfo()
      fetchMemberships()
      fetchTodayPayments()
    }
  }, []);

  const openPaymentModal = (payment) => {
    setState((prevState) => ({
      ...prevState,
      editPaymentModal: { display: true, payment },
    }))
  }

  const closePaymentModal = () => {
    setState((prevState) => ({
      ...prevState,
      editPaymentModal: { display: false, payment: null },
    }))
  }

  const submitPaymentsRange = (startDate, endDate) => {
    setState((prevState) => ({
      ...prevState,
      paymentsRangePicker: { startDate, endDate }
    }))
    fetchPaymentsRange(startDate, endDate)
  }

  const resetPaymentsRange = () => {
    setState((prevState) => ({
      ...prevState,
      paymentsRangePicker: { startDate: new Date(), endDate: new Date() }
    }))
    fetchTodayPayments()
  }

  const loggedInUsername = JSON.parse(sessionStorage.getItem("loggedInUser"))?.username;

  return (
    <>
      <Header memberships={memberships} fetchPaymentsInfo={fetchPaymentsInfo} selected="Пазар" />
      <EditPaymentModal 
        display={editPaymentModal.display}
        payment={editPaymentModal.payment}
        closeModal={closePaymentModal}
        editPayment={editPayment}
      />
      <Global.Wrapper>
        {loggedInUsername === 'brle' ? (
          <PaymentsInfo paymentsInfo={paymentsInfo} />
        ) : null}
        <Global.PaymentsFlexRow>
          <AddPayment fetchPaymentsInfo={fetchPaymentsInfo} fetchTodayPayments={fetchTodayPayments} />
          <PaymentsRangePicker resetPaymentsRange={resetPaymentsRange} submitPaymentsRange={submitPaymentsRange} />
        </Global.PaymentsFlexRow>
        <PaymentsRangeDisplay 
          paymentsRange={paymentsRange} 
          openPaymentModal={openPaymentModal} 
          paymentsRangePicker={paymentsRangePicker} 
        />
      </Global.Wrapper>
    </>
  );
}
 
export default Payments;